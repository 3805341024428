import { useCallback, useReducer } from 'react';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import clarity from '../service/clarity';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataUpdatePortfolio: null,
    errorUpdatePortfolio: null,
    isLoadingUpdatePortfolio: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setGetPortfolioData':
            return { ...state, dataGetPortfolio: action.payload };
        case 'setGetPortfolioError':
            return { ...state, errorGetPortfolio: action.payload };
        case 'setGetPortfolioIsLoading':
            return { ...state, isLoadingGetPortfolio: action.payload };
        case 'setDeletePortfolioData':
            return { ...state, dataDeletePortfolio: action.payload };
        case 'setDeletePortfolioError':
            return { ...state, errorDeletePortfolio: action.payload };
        case 'setDeletePortfolioIsLoading':
            return { ...state, isLoadingDeletePortfolio: action.payload };
        case 'setUpdatePortfolioData':
            return { ...state, dataUpdatePortfolio: action.payload };
        case 'setUpdatePortfolioError':
            return { ...state, errorUpdatePortfolio: action.payload };
        case 'setUpdatePortfolioIsLoading':
            return { ...state, isLoadingUpdatePortfolio: action.payload };
        case 'setCreatePortfolioData':
            return { ...state, dataCreatePortfolio: action.payload };
        case 'setCreatePortfolioError':
            return { ...state, errorCreatePortfolio: action.payload };
        case 'setCreatePortfolioIsLoading':
            return { ...state, isLoadingCreatePortfolio: action.payload };
        default:
            return state;
    }
};

export const useClarityWidget = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getPortfolios = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const response = await clarity.getPortfolios();

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const getPortfolio = useCallback(async ({ portfolioId } = {}) => {
        dispatch({ type: 'setGetPortfolioError', payload: null });
        dispatch({ type: 'setGetPortfolioIsLoading', payload: true });

        if (portfolioId == null
            || portfolioId.indexOf('null') !== -1
            || portfolioId.indexOf('undefined') !== -1) {
            return {
                name: null,
            };
        }

        try {
            const response = await clarity.getPortfolio(portfolioId);

            dispatch({ type: 'setGetPortfolioData', payload: response?.data });
            dispatch({ type: 'setGetPortfolioIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setGetPortfolioError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setGetPortfolioIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const deletePortfolio = useCallback(async ({ portfolioId } = {}) => {
        dispatch({ type: 'setDeletePortfolioError', payload: null });
        dispatch({ type: 'setDeletePortfolioIsLoading', payload: true });

        if (portfolioId == null
            || portfolioId.indexOf('null') !== -1
            || portfolioId.indexOf('undefined') !== -1) {
            return {
                name: null,
            };
        }

        try {
            const response = await clarity.deletePortfolio(portfolioId);

            dispatch({ type: 'setDeletePortfolioData', payload: response?.data });
            dispatch({ type: 'setDeletePortfolioIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setDeletePortfolioError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setDeletePortfolioIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const updatePortfolio = useCallback(async ({
        portfolioId, params,
    } = {}) => {
        dispatch({ type: 'setUpdatePortfolioError', payload: null });
        dispatch({ type: 'setUpdatePortfolioIsLoading', payload: true });

        if (portfolioId == null
            || portfolioId.indexOf('null') !== -1
            || portfolioId.indexOf('undefined') !== -1) {
            return {
                name: null,
            };
        }

        try {
            const response = await clarity.putPortfolio(portfolioId, params);

            dispatch({ type: 'setUpdatePortfolioData', payload: response?.data });
            dispatch({ type: 'setUpdatePortfolioIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setUpdatePortfolioError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setUpdatePortfolioIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const createPortfolio = useCallback(async ({ params } = {}) => {
        dispatch({ type: 'setCreatePortfolioError', payload: null });
        dispatch({ type: 'setCreatePortfolioIsLoading', payload: true });

        try {
            const response = await clarity.postPortfolios(params);

            dispatch({ type: 'setCreatePortfolioData', payload: response?.data });
            dispatch({ type: 'setCreatePortfolioIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setCreatePortfolioError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setCreatePortfolioIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getPortfolios,
        dataPortfolio: state.dataGetPortfolio,
        errorPortfolio: state.errorGetPortfolio,
        isLoadingPortfolio: state.isLoadingGetPortfolio,
        getPortfolio,
        dataDeletePortfolio: state.dataDeletePortfolio,
        errorDeletePortfolio: state.errorDeletePortfolio,
        isLoadingDeletePortfolio: state.isLoadingDeletePortfolio,
        deletePortfolio,
        dataUpdatePortfolio: state.dataUpdatePortfolio,
        errorUpdatePortfolio: state.errorUpdatePortfolio,
        isLoadingUpdatePortfolio: state.isLoadingUpdatePortfolio,
        updatePortfolio,
        dataCreatePortfolio: state.dataCreatePortfolio,
        errorCreatePortfolio: state.errorCreatePortfolio,
        isLoadingCreatePortfolio: state.isLoadingCreatePortfolio,
        createPortfolio,
    };
};
