import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import { PageNotFound } from 'pages/pages/404';
import RebalanceAdvisory from './products/RebalanceAdvisory';
import './Rebalance.css';

const RebalanceByProduct = generateObject({
    [ADVISORY]: RebalanceAdvisory,
    [DISCRETIONARY]: PageNotFound,
    [EXECUTION_ONLY]: PageNotFound,
});

function Rebalance(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const RebalancePage = RebalanceByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <RebalancePage {...props} />
        </Preloader>
    );
}

Rebalance.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

Rebalance.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Rebalance);
