import React from 'react';
import { Title, ContentBox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { OverviewPanel } from 'components/Overview';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import { Column } from 'ui-library/dist/components/Grid';
import { TYPE_RATIO } from 'utils/formatting';
import OverviewItem from './OverviewItem';
import { TYPE_PERCENT } from './constants';

function Overview({
    data, isLoading, error, additionalData,
}) {
    const { t } = useTranslation();

    return (
        <PageSection>
            <ContentBox underline={false}>
                <Title type={2} className="overview__header">
                    {t('overview.title')}
                </Title>
                <Preloader isLoading={isLoading} error={error}>
                    <OverviewPanel>
                        <OverviewItem
                            currency={data.currency}
                            title={t('overview.totalValue')}
                            type={TYPE_RATIO}
                            value={data.totalValue || 0}
                        />
                        <OverviewItem
                            title={t('overview.performanceSI')}
                            htmlTitle={t('overview.performanceSIFull')}
                            type={TYPE_PERCENT}
                            value={data.performanceValue || 0}
                            colored
                        />
                        {additionalData && (
                            <Column size="12" className="additional-data">
                                {additionalData()}
                            </Column>
                        )}
                    </OverviewPanel>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        securityValue: PropTypes.number,
        cashAmount: PropTypes.number,
        performanceAmount: PropTypes.number,
        totalValue: PropTypes.number,
        performanceValue: PropTypes.number,
        currency: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    additionalData: PropTypes.func,
};

Overview.defaultProps = {
    error: undefined,
    additionalData: undefined,
};

export default Overview;
