import { useEffect, useMemo } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { adaptMetrics } from './adapters/adaptMetrics';

const SEARCH_METRICS = gql`
    query SearchMetrics($positionId: Int!) {
        securities(
            where: {
                id: { eq: $positionId }
            }
        ){
            items {
                id
                name
                uId
                typeId
                tradingUnit
                risk
                tradingUnit
                currency { isoCode:threeLetterIsoCode }
                country { name }
                bondExtension { coupon, bondCouponFrequency { name }, maturityDate, issuerType { name } }
                optionExtension { isPut, optionStrike, maturityDate, optionExerciseType { name } }
                tradingPrice
                totalExpenseRatio
                projectedExpectedReturn
                futureExtension { maturityDate }
                riskCategory { name }
                ratings { rating { value, ratingAgency { name } } }
            }
        }
    }
`;

export const useMetrics = ({ positionId, additionalData }) => {
    const { t, i18n: { language } } = useTranslation();

    const [
        getMetrics,
        { loading: isLoading, error, data: dataMetrics },
    ] = useLazyQuery(SEARCH_METRICS);

    useEffect(() => {
        if (positionId) {
            getMetrics({ variables: { positionId: +positionId } });
        }
    }, [positionId, getMetrics]);


    const securityMetricsAdapted = useMemo(() => adaptMetrics(
        {
            data: dataMetrics?.securities?.items?.[0], additionalData, t, language,
        },
    ), [dataMetrics?.securities?.items, additionalData, language, t]);

    return {
        error,
        isLoading,
        data: securityMetricsAdapted,
    };
};
