export const CONTACT_LOCKED = 'ContactLocked';
export const MTAN_INVALID = 'MTanInvalid';
export const PASSCODE_INVALID = 'PasscodeInvalid';
export const USERNAME_INVALID = 'UsernameInvalid';
export const INVALID_CREDENTIALS = 'CredentialsInvalid';
export const EMAIL_INVALID = 'EmailInvalid';
export const EMAIL_MULTIPLE_CONTACTS = 'EmailMultipleContacts';
export const PASSWORD_SAME_ALREADY_SET = 'PasswordSameAlreadySet';
export const PASSWORD_POLICY_VALIDATION_FAILED = 'PasswordPolicyValidationFailed';
export const PASSWORD_SET_ACTIVE = 'PasswordSetActive';
export const ACTIVATION_KEY_INVALID = 'ActivationKeyInvalid';
export const USERNAME_AND_EMAIL_INVALID = 'UsernameAndEmailInvalid';
export const TWO_FACTOR_DISABLED = 'TwoFactorDisabled';
export const CONTACT_NOT_ACTIVATED = 'ContactNotActivated';
export const SESSION_EXPIRED = 'SessionExpired';
export const SESSION_INVALID = 'SessionInvalid';
export const DEVICE_MULTIPLE_ENROLLED = 'DeviceMultipleEnrolled';
export const DEVICE_ALREADY_ENROLLED = 'DeviceAlreadyEnrolled';
export const SUCCESS = 'Success';
export const SESSION_ALREADY_ACTIVE = 'SessionAlreadyActive';
export const ACCESS_DENIED = 'AccessDenied';
export const DEVICE_NOT_ENROLLED = 'DeviceNotEnrolled';
export const PASSWORD_INVALID = 'PasswordInvalid';
export const CONTACT_NOT_PASSWORD_SET = 'ContactNotPasswordSet';
export const EXPIRED = 'Expired';
export const PENDING = 'Pending';
export const MTAN_VERIFICATION_FAILED = 'MTanVerificationFailed';

// Local
export const UNEXPECTED_ERROR = 'UnexpectedError';
export const UNEXPECTED_ERROR_OR_INVALID_PASSCODE = 'UnexpectedErrorOrInvalidPasscode';
