import {
    BAR,
    LIST,
    PIE,
    PIE_SA,
} from 'constants/constants';
import { allocationPercentFormat, formatCurrencyWithPrecision } from 'utils/formatting';

import { checkNullData, roundArray } from 'utils';
import { sortAllocations } from 'utils/sortingAllocation';

const ALLOCATION_KEYS = {
    ASSET_CLASS: 'ParentAssetClass',
    COUNTRY: 'Country',
    CURRENCY: 'Currency',
    INSTRUMENT: 'Instrument',
    SECTOR: 'ParentSector',
    TYPE: 'Type',
};
const adaptAllocationData = (data = [], key, listNames, currency) => {
    if (checkNullData(data)) {
        return {
            [PIE_SA]: { data: [] },
            [PIE]: { data: [] },
            [BAR]: { data: [] },
            [LIST]: { data: [] },
        };
    }
    const getParent = (childId) => Object.keys(listNames)
        .find((parentKey) => listNames[parentKey].children.includes(childId));
    const groupedData = data
        .map((item) => ({ ...item, parentId: getParent(item.Id) }))
        .reduce((acc, item) => ({
            ...acc,
            [item.parentId]: {
                id: item.parentId,
                name: listNames[item.parentId]?.name,
                percent: item.Allocation,
                value: item.Amount,
            },
        }), {});

    const dataSorted = sortAllocations(key, Object.keys(groupedData).map((parentKey) => ({
        id: parentKey,
        name: groupedData[parentKey].name,
        percent: groupedData[parentKey].percent,
        value: groupedData[parentKey].value,
    })));

    roundArray(dataSorted.map((item) => item.percent))
        .forEach(
            (roundedPercent, index) => { dataSorted[index].percent = roundedPercent; },
        );

    return {
        [PIE]: {
            data: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                color: listNames[item.id]?.color,
                value: item.value,
                percent: item.percent,
            })),
            table: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                allocation: allocationPercentFormat(item.percent),
            })),
        },
        [PIE_SA]: {
            data: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                color: listNames[item.id]?.color,
                value: item.value,
                percent: item.percent,
            })),
        },
        [BAR]: {
            data: [{
                data: dataSorted.map((item) => ({
                    y: item.value, color: listNames[item.id]?.color,
                })),
            }],
            labels: dataSorted.map((item) => listNames[item.id]?.name),
        },
        [LIST]: {
            data: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                value: formatCurrencyWithPrecision(item.value, 0, currency),
                percent: allocationPercentFormat(item.percent),
            })),
        },
    };
};

const adaptAllocationDataMini = (data, key, listNames, currency) => {
    if (checkNullData(data)) {
        return {
            [PIE_SA]: { data: [] },
            [PIE]: { data: [] },
            [BAR]: { data: [] },
            [LIST]: { data: [] },
        };
    }

    const getParent = (childId) => Object.keys(listNames)
        .find((parentKey) => listNames[parentKey].children.includes(childId));
    const groupedData = data
        .map((item) => ({ ...item, parentId: getParent(item.Id) }))
        .reduce((acc, item) => ({
            ...acc,
            [item.parentId]: {
                id: item.parentId,
                name: listNames[item.parentId]?.name,
                percent: item.Allocation * 100,
                value: (acc[item.parentId]?.Amount || 0) + item.Amount,
            },
        }), {});

    const dataSorted = sortAllocations(key, Object.keys(groupedData).map((parentKey) => ({
        id: parentKey,
        name: groupedData[parentKey].name,
        value: groupedData[parentKey].value,
    })));

    return {
        [PIE]: {
            data: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                color: listNames[item.id]?.color,
                value: item.value,
                percent: item.percent,
            })),
            table: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                allocation: allocationPercentFormat(item.percent),
            })),
        },
        [PIE_SA]: {
            data: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                color: listNames[item.id]?.color,
                value: item.value,
                percent: item.percent,
            })),
        },
        [BAR]: {
            data: [{
                data: dataSorted.map((item) => ({
                    y: item.value, color: listNames[item.id]?.color,
                })),
            }],
            labels: dataSorted.map((item) => listNames[item.id]?.name),
        },
        [LIST]: {
            data: dataSorted.map((item) => ({
                name: listNames[item.id]?.name,
                value: formatCurrencyWithPrecision(item.value, 0, currency),
                percent: allocationPercentFormat(item.percent),
            })),
        },
    };
};

const adaptAllocation = (data, key, listNames, currency, mini) => {
    if (mini) return adaptAllocationDataMini(data, key, listNames, currency);

    return adaptAllocationData(data, key, listNames, currency);
};

export const adaptInvestmentAllocation = (data, listNames, currency, t) => {
    const Allocations = data?.Allocations;

    const assetClassData = getAllocationData(Allocations, ALLOCATION_KEYS.ASSET_CLASS);

    const typeData = getAllocationData(Allocations, ALLOCATION_KEYS.TYPE);
    const currencyData = getAllocationData(Allocations, ALLOCATION_KEYS.CURRENCY);
    const countryData = getAllocationData(Allocations, ALLOCATION_KEYS.COUNTRY);
    const sectorData = getAllocationData(Allocations, ALLOCATION_KEYS.SECTOR);

    return [
        {
            name: 'Asset classes',
            title: t('allocationTabs.assetClasses'),
            data: adaptAllocation(assetClassData, 'ParentAssetClass', listNames[0], currency),
            dataMini: adaptAllocation(assetClassData, 'ParentAssetClass', listNames[0], currency, true),
        },
        {
            name: 'Type',
            title: t('allocationTabs.type'),
            data: adaptAllocation(typeData, 'Type', listNames[1], currency),
            dataMini: adaptAllocation(typeData, 'Type', listNames[1], currency, true),
        },
        {
            name: 'Currency',
            title: t('allocationTabs.currencies'),
            data: adaptAllocation(currencyData, 'Currency', listNames[2], currency),
            dataMini: adaptAllocation(currencyData, 'Currency', listNames[2], currency, true),
        },
        {
            name: 'Region',
            title: t('allocationTabs.regions'),
            data: adaptAllocation(countryData, 'Country', listNames[3], currency),
            dataMini: adaptAllocation(countryData, 'Country', listNames[3], currency, true),
        },
        {
            name: 'Sector',
            title: t('allocationTabs.sectors'),
            data: adaptAllocation(sectorData, 'ParentSector', listNames[4], currency),
            dataMini: adaptAllocation(sectorData, 'ParentSector', listNames[4], currency, true),
        },
    ];
};

const getAllocationData = (data, key) => {
    if (!data || checkNullData(data)) return [];

    return data.find((el) => el?.Category === key)?.Breakdowns || [];
};
