import Service, { APIVersion } from 'additiv-services';
import { getClientServiceHeader, getClientServiceUrl, getServicesUrl } from 'additiv-services/dist/setup';

export default class CustomServiceLayer extends Service {
    // CLIENTSERVICES
    static async clientServicesGetSchemasListsItems() {
        return Service.getRequest(
            `${getClientServiceUrl(APIVersion.V11)}/schemas/lists/items`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async clientServicesGetMemberProfilePicture(memberId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/members/${memberId}/profilepicture`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async clientServicesGetContactProfilePicture(contactId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/contacts/${contactId}/profile-picture`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async clientServicesGetContactsAdvisorProfilePicture(contactId, memberId) {
        return Service.getRequest(
            `${process.env.REACT_APP_GATEWAY_API_URL}/${APIVersion.V10}/cls/contacts/${contactId}/member/${memberId}/profile-picture`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    // WMSSERVICES

    // ServiceManager.messageService('getMemberInboxMessages')
    static async wmsServicesPostMembersMessages(data, memberId) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V21)}/members/${memberId}/messages`,
            data,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    //  ServiceManager.messageService('getClientSentMessages'
    static async wmsServicesPostContactMessages(data, contactId) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V21)}/contacts/${contactId}/messages`,
            data,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async wmsServicesGetContactGroup(contactGroupId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/contactgroups/${contactGroupId}`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }
}
