import React, {
    createContext, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { contactIdSelector, jwtSelector } from 'redux/auth/authSelectors';
import ServiceManager from 'services/ServiceManager';
import {
    isNumber, isFinite, isObject, isEmpty,
} from 'lodash';

const UnreadMessagesContext = createContext({});

const { Provider } = UnreadMessagesContext;

let timer;
const delay = 60000;
const UnreadMessagesProvider = ({ children }) => {
    const [unreadMessages, setUnreadMessages] = useState(0);
    const contactId = useSelector(contactIdSelector);
    const jwt = useSelector(jwtSelector);

    const getMessages = useCallback(async () => {
        if (!contactId || !jwt) return;

        try {
            const { data } = await ServiceManager.messageService('getClientInboxMessages', [{
                pageSize: 1, contactId, isRead: false, IsDeleted: false, memberId: contactId,
            }]);

            if (data != null
                && isObject(data)
                && !isEmpty(data)
                && data.Total != null
                && isNumber(data.Total)
                && isFinite(data.Total)) {
                if (unreadMessages !== data.Total) {
                    setUnreadMessages(data.Total);
                }
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                clearInterval(timer);
            }
        }
    }, [contactId, unreadMessages, jwt]);

    useEffect(() => {
        (async () => {
            await getMessages();
        })();
        timer = setInterval(getMessages, delay);

        return () => {
            clearInterval(timer);
        };
    }, [getMessages]);

    return (
        <Provider value={unreadMessages}>
            {children}
        </Provider>
    );
};

UnreadMessagesProvider.propTypes = {
    children: PropTypes.node,
};

UnreadMessagesProvider.defaultProps = {
    children: null,
};

export {
    UnreadMessagesProvider,
    UnreadMessagesContext,
};
