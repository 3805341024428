import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import { PageNotFound } from 'pages/pages/404';
import QuickEditAdvisory from './products/QuickEditAdvisory';
import QuickEditExecutionOnly from './products/QuickEditExecutionOnly';

import './QuickEdit.css';

const QuickEditByProduct = generateObject({
    [ADVISORY]: QuickEditAdvisory,
    [DISCRETIONARY]: PageNotFound,
    [EXECUTION_ONLY]: QuickEditExecutionOnly,
});

function QuickEdit(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const QuickEditPage = QuickEditByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <QuickEditPage {...props} />
        </Preloader>
    );
}

QuickEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

QuickEdit.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(QuickEdit);
