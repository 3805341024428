import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { portfolioActions } from 'constants/constants';
import { useFormatting, useLocale } from 'locale';
import { APIVersion } from 'additiv-services';
import { useApolloClient } from '@apollo/client';
import { adaptInvestmentOverview } from '../adapters/adaptInvestmentOverview';
import { SECURITY_ATTRIBUTES } from '../constants/securityAtributes';

export const useWithInvestmentOverview = ({ clientId, proposals, usePortfolioOverview }) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({
        overview: {},
        investmentAllocation: { chart: [{ data: { pie: {}, bar: {}, list: {} } }] },
    });
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const { locale } = useLocale();
    const client = useApolloClient();

    const getInvestmentOverview = useCallback(async () => {
        setError(null);
        setLoading(true);

        try {
            const params = { language: i18n.language };
            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params, APIVersion.V11]);

            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);

            const {
                data: historicalPerfomance,
            } = await ServiceManager.portfolioManagement('getHistoricalPerformance', [clientId]);

            const securitiesIds = dataInvestment.ConsolidatedPositions
                .map(({ SecurityId }) => SecurityId);

            const { data: securityData } = await client.query({
                query: SECURITY_ATTRIBUTES,
                variables: { ids: securitiesIds },
            });

            const investmentDataFromEndpoint = {
                ...dataInvestment,
                ConsolidatedPositions:
                    dataInvestment.ConsolidatedPositions.map(({ Allocation, InvestmentValue },
                        index) => ({
                        Allocation,
                        InvestmentValue,
                        Security: securityData.securities.items[index],
                    })),
                Portfolios: allPortfolios,
                HistoricalPerformance: [
                    {
                        SeriesType: 'Portfolio',
                        Values: historicalPerfomance.Values,
                    },
                ],
            };

            const portfoliosOverview = usePortfolioOverview
                ? await Promise.all(allPortfolios
                    .map(async ({ Id }) => ({
                        Id,
                        ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                    })))
                : [];

            try {
                setData(
                    adaptInvestmentOverview(investmentDataFromEndpoint, {
                        proposals: proposals?.data,
                        portfoliosOverview,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                    }),
                );
                setLoading(false);

                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);

            return null;
        }
    }, [clientId,
        getFormattedCurrency,
        i18n.language,
        proposals?.data,
        t,
        usePortfolioOverview]);

    useEffect(() => {
        if (proposals?.isLoading) return;

        getInvestmentOverview();
    }, [getInvestmentOverview, proposals?.isLoading, locale]);

    return { data, isLoading, error };
};
