import {
    useCallback, useEffect, useMemo, useReducer,
} from 'react';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import AdapterError from 'errors/AdapterError';
import { getFirstDayOfYear } from 'utils/datetime';
import { FutureId, OptionId } from 'constants/instrument';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { gql, useLazyQuery } from '@apollo/client';
import { calculatePrice } from 'utils/priceCalculation';

const COMMON_INSTRUMENT = gql`
    query CommonInstrumentData($positionId: Int!) {
        securities(
            where: {
                id: { eq: $positionId }
            }
        ){
            items {
                id,
                uId
                name
                isin
                typeId
                factor
                roundingLot
                tradingUnit
                underlyingId
                tradingPrice
                isPricePercentage
                currency { id isoCode: threeLetterIsoCode }
                assetClass { id, name, parentId}
            }
        }
    }
`;

const BENCHMARK_DATA = gql`
    query BenchmarkData($positionId: Int!) {
        securities(
            where: {
                id: { eq: $positionId }
            }
        ){
            items {
                name
                risk
                tradingPrice
            }
        }
    }
`;

const initialState = {
    dataPerformance: {},
    errorPerformance: null,
    isLoadingPerformance: false,
    dataBenchmark: null,
    errorBenchmark: null,
    isLoadingBenchmark: false,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDataPerformance':
            return { ...state, dataPerformance: action.payload };
        case 'setErrorPerformance':
            return { ...state, errorPerformance: action.payload };
        case 'setIsLoadingPerformance':
            return { ...state, isLoadingPerformance: action.payload };
        case 'setDataBenchmark':
            return { ...state, dataBenchmark: action.payload };
        case 'setErrorBenchmark':
            return { ...state, errorBenchmark: action.payload };
        case 'setIsLoadingBenchmark':
            return { ...state, isLoadingBenchmark: action.payload };
        default:
            return state;
    }
};

export const useInstrumentData = (options) => {
    const { instrumentId: instrumentIdOption } = options;
    const [state, dispatch] = useReducer(reducer, initialState);
    // Callbacks
    const [
        getCommon, { loading: isLoading, error, data: raw },
    ] = useLazyQuery(COMMON_INSTRUMENT, { variables: { positionId: +instrumentIdOption } });
    const [
        getBenchmark, { loading: isLoadingBenchmark, error: errorBenchmark, data: rawBenchmark },
    ] = useLazyQuery(BENCHMARK_DATA);

    const getPerformance = useCallback(async (params) => {
        const {
            instrumentId = instrumentIdOption,
            currencyId,
        } = params;

        dispatch({ type: 'setErrorPerformance', payload: null });
        dispatch({ type: 'setIsLoadingPerformance', payload: true });

        const paramsKPI = {
            InstrumentSet: {
                CurrencyId: currencyId,
                Allocations: [{ InstrumentId: +instrumentId, Allocation: 1 }],
                AllocationType: 'Percentage',
            },
            Years: 10,
            CalculationInterval: 'Monthly',
        };

        const paramsPerformance = {
            fromDate: getFirstDayOfYear(),
            toDate: new Date(),
        };

        try {
            const [{ data: KpiData }, { data: perfData }] = await Promise.all([
                SM.performance('calculateKPIPerformance', [paramsKPI]),
                SM.instrumentsService('postPerformanceHistory', [instrumentId, paramsPerformance]),
            ]);

            try {
                const { Volatility, ExpectedReturn, SharpeRatio } = KpiData || {};
                const { length, [length - 1]: last } = perfData?.historicalData || [];
                const dataToReturn = {
                    Volatility,
                    SharpeRatio,
                    ExpectedReturn,
                    performanceYTD: last?.cumPerf * 100 || 0,
                };

                dispatch({ type: 'setDataPerformance', payload: dataToReturn });
                dispatch({ type: 'setIsLoadingPerformance', payload: false });

                return dataToReturn;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorPerformance', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingPerformance', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [instrumentIdOption]);

    // Data
    const data = useMemo(() => {
        const instrument = raw?.securities?.items?.[0] || {};
        const price = calculatePrice({
            tradingPrice: instrument.tradingPrice,
            isPricePercentage: instrument.isPricePercentage,
            factor: instrument.factor,
            typeId: instrument.typeId,
        });

        return { ...instrument, price };
    }, [raw]);
    const dataBenchmark = useMemo(() => rawBenchmark?.securities?.items?.[0] || {},
        [rawBenchmark]);

    // Effects
    useEffect(() => {
        if (data.underlyingId && data.currency?.id && [OptionId, FutureId].includes(data.typeId)) {
            getBenchmark({ variables: { positionId: data.underlyingId } });
        }
    }, [data, getBenchmark]);

    return {
        data,
        error,
        isLoading,
        getCommon,
        dataPerformance: state.dataPerformance,
        errorPerformance: state.errorPerformance,
        isLoadingPerformance: state.isLoadingPerformance,
        getPerformance,
        dataBenchmark,
        errorBenchmark,
        isLoadingBenchmark,
        getBenchmark,
    };
};
