import { isNil } from 'lodash/fp';
import { trimLongString } from 'utils/formatting';
import { checkNullData } from 'utils';
import { formatDate } from 'utils/datetime';
import { dateTimeFormats } from 'locale';

export const adaptMessages = (data, memberId) => {
    if (checkNullData(data)) return [];

    return data.map(
        (
            {
                Id,
                CreateDate,
                IsActive,
                IsRead: isReadCommon,
                IsDeleted,
                Subject,
                SenderId,
                SenderFullName,
                RecipientFullName,
                Text,
                CategoryName,
                Recipients,
            },
            index,
        ) => {
            const recipientObj = Recipients.find(
                ({ RecipientId }) => RecipientId === memberId,
            ) || {};
            const isRead = memberId && !isReadCommon
                ? memberId === SenderId || recipientObj.IsRead
                : isReadCommon;

            return {
                id: Id,
                date: formatDate(CreateDate),
                isActive: IsActive,
                isDeleted: IsDeleted,
                title: Subject,
                senderId: SenderId,
                isSelected: index === 0,
                isChecked: false,
                senderFullName: SenderFullName,
                recipientFullName: RecipientFullName,
                content: Text,
                categoryName: CategoryName,
                recipients: Recipients.map(
                    ({ RecipientId: recipientId, FullName: recipientFullName }) => ({
                        recipientId,
                        recipientFullName,
                    }),
                ),
                Subject: {
                    title: trimLongString(Subject, 30),
                    subtitle: Text,
                    isRead,
                },
                Date: {
                    date: CreateDate,
                    isReplied: false,
                },
            };
        },
    );
};

export const adaptMessageDetails = (data, memberId, getFormattedDate) => {
    const recipientObj = data.Recipients.find(({ RecipientId }) => RecipientId === memberId) || {};
    const isRead = memberId && !data.IsRead
        ? memberId === data.SenderId || recipientObj.IsRead : data.IsRead;

    const date = getFormattedDate(data.CreateDate, {
        hour: dateTimeFormats.TWO_DIGIT,
        minute: dateTimeFormats.TWO_DIGIT,
        second: dateTimeFormats.TWO_DIGIT,
    });

    return isNil(data)
        ? null
        : {
            isRead,
            isFull: true,
            id: data.Id,
            date,
            isActive: data.IsActive,
            isDeleted: data.IsDeleted,
            title: data.Subject,
            content: data.Text,
            subtypeId: data.SubtypeId,
            subtypeName: data.SubtypeName,
            sourceId: data.SourceId,
            sourceName: data.SourceName,
            senderId: data.SenderId,
            senderFullName: data.SenderFullName,
            Subject: {
                title: trimLongString(data.Subject, 30),
                subtitle: trimLongString(data.Text, 30),
                isRead,
            },
            recipients:
                  data.Recipients
                  && data.Recipients.map(
                      ({
                          RecipientId,
                          FullName,
                          IsRead: RecipientIsRead,
                          IsDeleted: RecipientIsDeleted,
                          ThreadId,
                          CampaignId,
                          ThreadShortName,
                      }) => ({
                          recipientId: RecipientId,
                          recipientFullName: FullName,
                          isRead: RecipientIsRead,
                          isDeleted: RecipientIsDeleted,
                          threadId: ThreadId,
                          campaignId: CampaignId,
                          threadShortName: ThreadShortName,
                      }),
                  ),
            attachments:
                  data.Attachments
                  && data.Attachments.map(({ FileName, DocumentId }) => ({
                      fileName: FileName,
                      documentId: DocumentId,
                  })),
        };
};
