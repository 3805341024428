import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userNameAndLastNameSelector } from 'redux/auth/authSelectors';
import { api } from 'lib/unblu-visitor-js-api';
import { useTheme } from 'theming';

const ANONYMOUS = 'Anonymous';

// HWM-702: HOT FIX for Unblu library
const CLEAR_UNBLU_CONFIGURE = () => {
    const unblu = document.getElementsByTagName('unblu')?.[0];

    if (api.state !== 'INITIAL') api.state = 'INITIAL';
    if (window.unblu.APIKEY) delete window.unblu.APIKEY;
    if (unblu) unblu.remove();
};
const apiKeyByTheme = {
    light: global?.AppConfig?.REACT_APP_UNBLU_API_KEY,
    dark: global?.AppConfig?.REACT_APP_UNBLU_API_KEY_DARK,
};

export const useUnblu = () => {
    const [apiToUse, setApiToUse] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const userName = useSelector(userNameAndLastNameSelector);

    const { theme } = useTheme();
    const apiKey = apiKeyByTheme[theme] || global?.AppConfig?.REACT_APP_UNBLU_API_KEY;

    useEffect(() => {
        if ((global?.AppConfig?.REACT_APP_UNBLU_URL?.length ?? 0) === 0) {
            setLoading(false);

            return;
        }
        setLoading(true);
        CLEAR_UNBLU_CONFIGURE();
        api.configure({
            apiKey,
            serverUrl: global?.AppConfig?.REACT_APP_UNBLU_URL,
        })
            .initialize()
            .then((apiResult) => {
                setApiToUse(apiResult);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(err);
            });
    }, [apiKey]);

    useEffect(() => {
        if (apiToUse !== undefined) {
            apiToUse.setPersonNickname(userName || ANONYMOUS);
        }
    }, [apiToUse, userName]);

    return { isLoading, error };
};
