import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    BreadCrumbs,
    Button,
    Column,
    ContentBox,
    Row,
    Select,
    TextInput,
    Textarea,
    Title,
} from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import { contactIdSelector, memberIdSelector } from 'redux/auth/authSelectors';
import './NewMessage.css';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useNewMessage } from '../hooks/useNewMessage';

function NewMessage(props) {
    const { location, contactId } = props;
    const { t } = useTranslation();

    const {
        values,
        onChange,
        onCancel,
        formErrors,
        control,
        handleSubmit,
        isSending,
        messageSubTypesOptions,
        sendMessage,
        isLoading,
    } = useNewMessage({ contactId, location });

    return (
        <PageSection className="new-message">
            <ContentBox>
                <Row>
                    <Column size={['sm-12']}>
                        <BreadCrumbs current={t('messages.newMessage')}>
                            <Link to="/messages">{t('messages.title')}</Link>
                        </BreadCrumbs>
                        <Title type={1} className="mb-0">
                            {t('messages.newMessageToAdvisor')}
                        </Title>
                    </Column>
                </Row>
                <hr />
                <Preloader isLoading={isLoading} error={null}>
                    <Row>
                        <Column size={['sm-6']}>
                            <Select
                                control={control}
                                name="contactReason"
                                value={values.contactReason}
                                onChange={onChange('contactReason')}
                                options={messageSubTypesOptions}
                                label={t('messages.category')}
                                withInfobox={false}
                                error={formErrors.contactReason}
                                required
                            />
                            <br />
                            <TextInput
                                name="subject"
                                control={control}
                                value={values.subject}
                                onChange={onChange('subject')}
                                label={t('messages.subject')}
                                error={formErrors.subject}
                                required
                            />
                            <br />
                            <Controller
                                as={<Textarea />}
                                name="message"
                                control={control}
                                label={t('messages.message')}
                                defaultValue={values.message}
                                error={formErrors.message}
                                onChange={onChange('message')}
                                required
                            />
                        </Column>
                    </Row>
                    <Row className="buttons">
                        <Column size={12} className="text-sm-right button-group">
                            <Button type="secondary" size="small" onClick={onCancel}>
                                {t('messages.cancel')}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                disabled={isSending}
                                onClick={handleSubmit(sendMessage)}
                            >
                                {t('messages.send')}
                            </Button>
                        </Column>
                    </Row>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

NewMessage.propTypes = {
    location: PropTypes.shape({
        state: {
            title: PropTypes.string,
            proposalId: PropTypes.string,
            portfolioId: PropTypes.string,
        },
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

NewMessage.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
    memberId: memberIdSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
