import uniq from 'lodash/uniq';
import { getfromDataObject, validateStringData } from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import {
    sortStatuses,
    portfolioStatuses,
    portfolioStatusesIcons,
    portfolioStatusesColors,
    PENDING_ORDERS,
    NOT_IMPLEMENTED,
    IN_EXECUTION,
    BLOCKED, CLOSED,
    getPortfolioStatus,
    PORTFOLIO_CLOSED_ID,
} from 'constants/portfolioStatuses';
import { EXECUTION_ONLY_ID } from 'constants/portfolioProducts';

export const adaptInvestmentOverview = (data, {
    proposals = [], portfolioActions, t, getFormattedCurrency, portfoliosOverview,
}) => {
    let positions;
    const currency = data?.BaseCurrency?.CurrencyCode;

    if (data?.Positions) {
        positions = data?.Positions;
    } else if (data?.ConsolidatedPositions) {
        positions = data?.ConsolidatedPositions;
    }
    const pendingOrders = portfoliosOverview?.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item?.HasPendingOrders,
    }), {}) || {};

    const proposalsAdapted = adaptProposalsByPortfolioId(proposals);
    const portfolios = data?.Portfolios !== undefined ? data.Portfolios
        .filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN)
        .filter((item) => item.PortfolioStatusId !== PORTFOLIO_CLOSED_ID)
        .map((item) => {
            const proposalStatuses = sortStatuses(
                uniq((proposalsAdapted[item.Id] || []).map(({ status }) => status)),
            );

            const portfolioStatus = getPortfolioStatus({
                portfolioStatusId: item.PortfolioStatusId,
                isBreached: item?.IsBreached,
                isOrderBookBlocked: pendingOrders?.[item.Id],
                proposalStatus: proposalStatuses?.[0],
            });

            const link = () => {
                if (portfolioStatus === NOT_IMPLEMENTED) {
                    return `/portfolios/${item.Id}/funding-information`;
                }
                if (portfolioStatus === IN_EXECUTION) {
                    return `/portfolios/${item.Id}/pending-orders`;
                }

                return `/portfolios/${item.Id}`;
            };

            const isStrategyHidden = EXECUTION_ONLY_ID.includes(getfromDataObject(item, 'Product.Id'));

            return {
                Id: item.Id,
                Name: {
                    label: item.Name,
                    link,
                    disabled: portfolioStatus === CLOSED,
                },
                Product: getfromDataObject(item, 'Product.Name'),
                ProductId: item?.Product.Id,
                Status: {
                    text: portfolioStatuses(t)[portfolioStatus] || '-',
                    icon: portfolioStatusesIcons[portfolioStatus] || '',
                    color: portfolioStatusesColors[portfolioStatus] || '',
                    value: portfolioStatus,
                },
                Profile: isStrategyHidden ? '' : validateStringData(item.RiskCategory.Name),
                Value: getFormattedCurrency(item.CurrentValue, {
                    currency: item.Currency?.CurrencyCode,
                }),
                Performance: item.CurrentPerformance ? item.CurrentPerformance * 100 : undefined,
                Actions: {
                    value: 'action',
                    actions: portfolioActions({
                        id: item.Id,
                        productName: item.Product?.Name,
                        t,
                        withEditActions:
                            portfolioStatus !== PENDING_ORDERS && portfolioStatus !== BLOCKED,
                        withPendingOrders: portfolioStatus === IN_EXECUTION,
                        withFundingInformation: portfolioStatus === NOT_IMPLEMENTED,
                    }),
                    disabled: portfolioStatus === CLOSED,
                },
            };
        }) : [];

    return {
        overview: {
            currency,
            securityValue: data?.SecuritiesValue,
            totalValue: data?.CurrentValue,
            cashAmount: data?.CashAmount,
            performanceValue: data?.CurrentPerformance * 100,
            performanceAmount: data?.CurrentPerformanceAmount,
        },
        positions,
        portfolios,
        productIds: portfolios.map(({ ProductId }) => ProductId),
    };
};
