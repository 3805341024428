import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting, useLocale } from 'locale';
import { getPeriodByFilter } from 'utils';
import { adaptInvestmentOverviewPerformance } from '../adapters/adaptInvestmentOverviewPerformance';

export const useWithInvestmentOverviewPerformance = (
    filter,
    clientId,
    currency,
    isLoadingCurrency,
    isMobileSize,
) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({
        assets: [{}],
        performance: [{}],
    });
    const { i18n } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency, getFormattedDate } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        const { startDate, endDate } = getPeriodByFilter(filter);
        const params = startDate === undefined
            ? {}
            : { from: startDate, to: endDate, language: i18n.language };

        setLoading(true);

        if (!isLoadingCurrency) {
            ServiceManager.portfolioManagement('getInvestmentOverviewPerformance', [
                clientId,
                params,
            ])
                .then((response) => {
                    try {
                        setData(
                            adaptInvestmentOverviewPerformance(
                                response.data.Values,
                                currency,
                                startDate,
                                filter,
                                isMobileSize,
                                getFormattedNumber,
                                getFormattedCurrency,
                                getFormattedDate,
                            ),
                        );
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        }
    }, [clientId, filter, isLoadingCurrency, i18n.language, locale]);

    return { data, isLoading, error };
};
