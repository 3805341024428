import { getTimestamp, subtractUnits } from 'utils/datetime';
import { formatCurrencyWithPrecision, formatWithDate } from 'utils/formatting';
import { isNeedToAddFlatLine, adaptStartDate } from 'utils/charts';
import { adaptPlotsAmount } from 'utils/adaptPlotsAmount';

const adaptDate = (Date) => getTimestamp(Date);

export const adaptInvestmentOverviewPerformance = (
    rawData,
    currency,
    chartStartDate,
    filter,
    isMobileSize,
    getFormattedNumber,
    getFormattedCurrency,
    getFormattedDate,
) => {
    const data = adaptPlotsAmount(rawData, isMobileSize);

    const adaptedInvestmentOverviewPerformance = {
        performance: [
            {
                data: [],
                tooltipFormat: (number, date) => formatWithDate(`${getFormattedNumber(number)}%`, date, getFormattedDate),
            },
        ],
        assets: [
            {
                data: [],
                tooltipFormat: (number, date) => formatWithDate(
                    getFormattedCurrency
                        ? getFormattedCurrency(number, { currency: currency.CurrencyCode })
                        : formatCurrencyWithPrecision(number, 0, currency.CurrencyCode),
                    date,
                    getFormattedDate,
                ),
            },
        ],
    };

    data.forEach(({ Value, Amount, Date: date }, idx) => {
        if (idx === 0) {
            const start = adaptStartDate(chartStartDate, date);

            if (isNeedToAddFlatLine(start, date)) {
                const timestampStart = adaptDate(start);

                adaptedInvestmentOverviewPerformance.performance[0].data.push([timestampStart, 0]);
                adaptedInvestmentOverviewPerformance.assets[0].data.push([timestampStart, Amount]);

                adaptedInvestmentOverviewPerformance.performance[0].data.push([
                    adaptDate(subtractUnits(date, 1, 'days')),
                    0,
                ]);
                adaptedInvestmentOverviewPerformance.assets[0].data.push([
                    adaptDate(subtractUnits(date, 1, 'days')),
                    Amount,
                ]);
            }
        }

        const timestampStart = adaptDate(date);

        const adaptedValue = Math.round(Value * 10000) / 100;

        adaptedInvestmentOverviewPerformance.performance[0].data.push([
            timestampStart,
            adaptedValue,
        ]);

        adaptedInvestmentOverviewPerformance.assets[0].data.push([timestampStart, Amount]);
    });

    return adaptedInvestmentOverviewPerformance;
};
