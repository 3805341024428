import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { portfolioActions } from 'constants/constants';
import { useFormatting, useLocale } from 'locale';
import { APIVersion } from 'additiv-services';
import { adaptPortfolios } from '../adapters/adaptPortfolios';

export const usePortfolios = (clientId, proposals) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const { locale } = useLocale();

    const getInvestmentOverview = useCallback(async () => {
        setError(null);
        setLoading(true);

        try {
            const params = { language: i18n.language };

            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params, APIVersion.V11]);

            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);
            const {
                data: historicalPerfomance,
            } = await ServiceManager.portfolioManagement('getHistoricalPerformance', [clientId]);

            const investmentDataFromEndpoint = {
                ...dataInvestment,
                Portfolios: allPortfolios,
                HistoricalPerformance: [
                    {
                        SeriesType: 'Portfolio',
                        Values: historicalPerfomance.Values,
                    },
                ],
            };

            const portfoliosOverview = await Promise.all(allPortfolios
                .map(async ({ Id }) => ({
                    Id,
                    ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                })));


            try {
                setData(
                    adaptPortfolios(investmentDataFromEndpoint, {
                        proposals: proposals.data,
                        portfoliosOverview,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                    }),
                );
                setLoading(false);


                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);

            return null;
        }
    }, [clientId, getFormattedCurrency, i18n.language, proposals.data, t]);

    useEffect(() => {
        if (proposals?.isLoading) return;

        getInvestmentOverview();
    }, [getInvestmentOverview, proposals.isLoading, locale]);

    return { data, isLoading, error };
};
