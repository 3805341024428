import { useEffect, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptData } from '../pages/pages/Portfolios/pages/ModifyPortfolio/pages/AddPosition/adapters/adaptData';

export const useCurrencies = () => {
    const [errorCurrency, setError] = useState(null);
    const [isLoadingCurrency, setLoading] = useState(true);
    const [dataCurrency, setData] = useState({});
    const currencyParams = {
        page: 1,
        pageSize: 1000,
    };

    useEffect(() => {
        setLoading(true);
        ServiceManager.currenciesService('searchCurrencies', [currencyParams])
            .then((response) => {
                try {
                    setData(adaptData(response.data.Results));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, []);

    return { dataCurrency, isLoadingCurrency, errorCurrency };
};
