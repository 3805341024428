import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, get } from 'lodash';
import { useApolloClient, gql } from '@apollo/client';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const GET_LIQUIDITY = gql`
    query GetLiquidity($positionId: Int!) {
        securities(
            where: { id: { eq: $positionId } }
        ){
             items {
                id
                name
                typeId
                tradingPrice
                currency { id isoCode:threeLetterIsoCode }
                assetClass { id, name, parentId }
            }
        }
    }
`;

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataGoalTemplates: null,
    dataGoalPictures: [],
    errorGoalTemplates: null,
    isLoadingGoalTemplates: false,
    dataCurrencies: null,
    errorCurrencies: null,
    isLoadingCurrencies: false,
    dataRecurrences: null,
    errorRecurrences: null,
    isLoadingRecurrences: false,
    dataLiquidity: null,
    errorLiquidity: null,
    isLoadingLiquidity: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setGoalTemplatesData':
            return { ...state, dataGoalTemplates: action.payload };
        case 'setGoalTemplatesPictures':
            return { ...state, dataGoalPictures: action.payload };
        case 'setGoalTemplatesError':
            return { ...state, errorGoalTemplates: action.payload };
        case 'setGoalTemplatesIsLoading':
            return { ...state, isLoadingGoalTemplates: action.payload };
        case 'setCurrenciesData':
            return { ...state, dataCurrencies: action.payload };
        case 'setCurrenciesError':
            return { ...state, errorCurrencies: action.payload };
        case 'setCurrenciesIsLoading':
            return { ...state, isLoadingCurrencies: action.payload };
        case 'setRecurrencesData':
            return { ...state, dataRecurrences: action.payload };
        case 'setRecurrencesError':
            return { ...state, errorRecurrences: action.payload };
        case 'setRecurrencesIsLoading':
            return { ...state, isLoadingRecurrences: action.payload };
        case 'setLiquidityData':
            return { ...state, dataLiquidity: action.payload };
        case 'setLiquidityError':
            return { ...state, errorLiquidity: action.payload };
        case 'setLiquidityIsLoading':
            return { ...state, isLoadingLiquidity: action.payload };
        default:
            return state;
    }
};

export const useGoalCreation = (options = {}) => {
    const {
        loadInitially = false,
        productId: productIdOption,
        riskCategoryId: riskCategoryIdOption,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const client = useApolloClient();
    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getModelPortfolioId = useCallback(async ({
        productId = productIdOption,
        riskCategoryId = riskCategoryIdOption,
    } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        const params = { language, riskCategoryId };

        try {
            if (!isEmpty(params.modelPortfolioId)) {
                const response = await SM.portfolioManagement('getModelPortfolios', [productId, params]);
                const modelPortfolioId = response?.data?.[0];

                dispatch({ type: 'setData', payload: modelPortfolioId });
                dispatch({ type: 'setIsLoading', payload: false });

                return modelPortfolioId;
            }

            dispatch({ type: 'setIsLoading', payload: false });
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, productIdOption, riskCategoryIdOption]);
    const getGoalTemplates = useCallback(async ({
        productId = productIdOption,
    }) => {
        dispatch({ type: 'setGoalTemplatesError', payload: null });
        dispatch({ type: 'setGoalTemplatesIsLoading', payload: true });

        try {
            const response = await SM.goalsService('getTemplates', [language, productId]);
            const pictures = await Promise.all((response?.data || []).map(async ({ Id }) => ({
                Id,
                ...(await SM.goalsService('getTemplatePicture', [Id])).data,
            })));

            dispatch({ type: 'setGoalTemplatesData', payload: response?.data });
            dispatch({ type: 'setGoalTemplatesPictures', payload: pictures });
            dispatch({ type: 'setGoalTemplatesIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setGoalTemplatesError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setGoalTemplatesIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, productIdOption]);
    const getCurrencyCodes = useCallback(async () => {
        dispatch({ type: 'setCurrenciesError', payload: null });
        dispatch({ type: 'setCurrenciesIsLoading', payload: true });

        try {
            const response = await SM.commonService('getCampaignsCurrentConfiguration', [language]);

            dispatch({ type: 'setCurrenciesData', payload: response?.data });
            dispatch({ type: 'setCurrenciesIsLoading', payload: false });
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setCurrenciesError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setCurrenciesIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);
    const getRecurrences = useCallback(async () => {
        dispatch({ type: 'setRecurrencesError', payload: null });
        dispatch({ type: 'setRecurrencesIsLoading', payload: true });

        try {
            const response = await SM.commonService('getEnumerations', [
                {
                    EnumerationTypes: ['RecurrenceType'],
                },
                language,
            ]);

            dispatch({ type: 'setRecurrencesData', payload: get(response, 'data.Enumerations[0].Values', []) });
            dispatch({ type: 'setRecurrencesIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setRecurrencesError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setRecurrencesIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);
    const getLiquidity = useCallback(async ({
        currencyId,
        productId = productIdOption,
    } = {}) => {
        dispatch({ type: 'setLiquidityError', payload: null });
        dispatch({ type: 'setLiquidityIsLoading', payload: true });

        try {
            const params = {
                SecurityTypeIds: [1],
                CurrencyIds: [currencyId],
                Page: 1,
                PageSize: 1,
            };
            const securityIdResponse = await SM.instrumentsService('searchSecurities', [productId, params, { language }]);

            const securityId = securityIdResponse?.data?.Results?.[0]?.Id;
            const { data: response } = await client.query({
                query: GET_LIQUIDITY,
                variables: { positionId: securityId },
            });
            const securityData = response?.securities?.items?.[0];

            dispatch({ type: 'setLiquidityData', payload: securityData });
            dispatch({ type: 'setLiquidityIsLoading', payload: false });

            return securityData;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setLiquidityError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setLiquidityIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [client, language, productIdOption]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getModelPortfolioId();
    }, [getModelPortfolioId, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getModelPortfolioId,
        dataGoalTemplates: state.dataGoalTemplates,
        dataGoalPictures: state.dataGoalPictures,
        errorGoalTemplates: state.errorGoalTemplates,
        isLoadingGoalTemplates: state.isLoadingGoalTemplates,
        getGoalTemplates,
        dataCurrencies: state.dataCurrencies,
        errorCurrencies: state.errorCurrencies,
        isLoadingCurrencies: state.isLoadingCurrencies,
        getCurrencyCodes,
        dataRecurrences: state.dataRecurrences,
        errorRecurrences: state.errorRecurrences,
        isLoadingRecurrences: state.isLoadingRecurrences,
        getRecurrences,
        dataLiquidity: state.dataLiquidity,
        errorLiquidity: state.errorLiquidity,
        isLoadingLiquidity: state.isLoadingLiquidity,
        getLiquidity,
    };
};
