import React, {
    useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contactIdSelector } from 'redux/auth/authSelectors';
import {
    ContentBox, Table, Tabs, TabPane,
} from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PageHeader from 'components/PageHeader';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import useDocuments from './hooks/useDocument';
import getColumns from './constants';
import { DocumentUpload } from './components/DocumentUpload';
import './Documents.css';


// eslint-disable-next-line react/prop-types
function Documents({ contactId: clientId, location }) {
    const { state } = location;
    const currentPage = state?.currentPage || 1;
    const currentTab = state?.currentTab || 'personal';

    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const [currentPagination, setCurrentPagination] = useState(currentPage);
    const [tab, setTab] = useState(currentTab);
    const [uploadedId, setUploadedId] = useState(0);
    const [docError, setDocError] = useState(null);

    const {
        data,
        generalData,
        isLoading,
        isGeneralLoading,
        error: useDocumentsError,
        pageSize,
        personalPaging,
        generalPaging,
    } = useDocuments({
        clientId,
        currentPagination,
        tab,
        fileUploaded: uploadedId,
        filterByMetadataId: true,
    });

    useEffect(() => {
        setDocError(useDocumentsError);
        const errMsg = useDocumentsError?.error?.response?.data?.message?.toLowerCase();

        // if server could not find file, show custom message correctly
        if (errMsg?.includes('not found') || errMsg?.includes('not exist')) {
            setDocError({
                ...docError,
                message: t('dashboard.documents.noDocFound'),
            });
        }
    }, [useDocumentsError]);

    const onChangeTabs = (key) => {
        setTab(key);
        setCurrentPagination(1);

        // clear any exisitng erros on tab change
        setDocError(null);
    };

    const onChangePage = useCallback((paging) => {
        setCurrentPagination(paging?.currentPage);
    }, [setCurrentPagination]);

    return (
        <PageSection>
            <ContentBox className="documents">
                {/* <PageHeader title={t('dashboard.documents.title')}>
                    <DocumentUpload contactId={clientId} onUpload={setUploadedId} />
                </PageHeader> */}
                <Tabs activeKey={tab} className="Tabs-bar" defaultActiveKey="personal" onChange={onChangeTabs}>
                    <TabPane tab={t('dashboard.documents.personal')} key="personal">
                        <Preloader
                            isLoading={isLoading}
                            error={docError}
                            letChildrenToRenderOnError
                        >
                            <EmptyContent data={data} text={t('dashboard.documents.noDocuments')}>
                                <Table
                                    data={data}
                                    columns={getColumns(t, isMobileSize, clientId, setUploadedId)}
                                    paging={{
                                        total: personalPaging?.TotalCount || 0,
                                        pageSize,
                                        currentPage: currentPagination,
                                    }}
                                    headerControls={{
                                        selectControlsLabel: {
                                            selectAll: t('headerControls.selectAll'),
                                            reset: t('headerControls.reset'),
                                        },
                                    }}
                                    onChange={onChangePage}
                                />
                            </EmptyContent>
                        </Preloader>
                    </TabPane>

                    <TabPane tab={t('dashboard.documents.general')} key="general">
                        <Preloader
                            isLoading={isGeneralLoading}
                            error={docError}
                            letChildrenToRenderOnError
                        >
                            <EmptyContent data={generalData} text={t('dashboard.documents.noDocuments')}>
                                <Table
                                    data={generalData}
                                    columns={getColumns(t, isMobileSize, clientId, setUploadedId)}
                                    paging={{
                                        total: generalPaging?.TotalCount || 0,
                                        pageSize,
                                        currentPage: currentPagination,
                                    }}
                                    headerControls={{
                                        selectControlsLabel: {
                                            selectAll: t('headerControls.selectAll'),
                                            reset: t('headerControls.reset'),
                                        },
                                    }}
                                    onChange={onChangePage}
                                />
                            </EmptyContent>
                        </Preloader>
                    </TabPane>
                </Tabs>
            </ContentBox>
        </PageSection>
    );
}

Documents.propTypes = {
    match: PropTypes.shape({
        params:
            PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            currentPage: PropTypes.number,
            currentTab: PropTypes.string,
        }),
    }),
};

Documents.defaultProps = {
    location: {
        state: {},
    },
};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Documents);
