import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePortfolioDetails } from '../hooks/usePortfolioDetails';
import { useModelPortfolio } from '../hooks/useModelPortfolio';
import { useOrderBook } from '../hooks/useOrderBook';
import { useTradingCard } from '../hooks/useTradingCard';
import { usePortfolioModify } from '../hooks/usePortfolioModify';
import { usePortfolioQuickEdit } from '../hooks/usePortfolioQuickEdit';
import { useTransactions } from '../hooks/useTransactions';
import { useCashFlows } from '../hooks/useCashFlows';
import { useProposalDetails } from '../hooks/useProposalDetails';
import PortfolioContext from './context';

const PortfolioOverviewProvider = (options) => {
    const {
        options: {
            id,
            clientId,
            portfolio: portfolioOptions,
            modelPortfolio: modelPortfolioOptions,
            orderBook: orderBookOptions,
            tradingCard: tradingCardOptions,
            transactions: transactionsOptions,
            cashFlows: cashFlowsOptions,
            proposalDetails: proposalDetailsOptions,
        },
        children,
    } = options;
    const modelPortfolio = useModelPortfolio(modelPortfolioOptions);
    const portfolio = usePortfolioDetails(
        { ...portfolioOptions, clientId, portfolioId: id }, modelPortfolio,
    );
    const orderBook = useOrderBook({ ...orderBookOptions, clientId, portfolioId: id });
    const tradingCard = useTradingCard({ ...tradingCardOptions, clientId, portfolioId: id });
    const portfolioModify = usePortfolioModify({ clientId, portfolioId: id }, tradingCard);
    const portfolioQuickEdit = usePortfolioQuickEdit();
    const transactions = useTransactions({ ...transactionsOptions, clientId, portfolioId: id });
    const cashFlows = useCashFlows({ ...cashFlowsOptions, clientId, portfolioId: id });
    const proposal = useProposalDetails({ ...proposalDetailsOptions, clientId, portfolioId: id });
    const state = {
        id,
        clientId,
        portfolio,
        modelPortfolio,
        orderBook,
        tradingCard,
        portfolioModify,
        portfolioQuickEdit,
        transactions,
        cashFlows,
        proposal,
    };
    const getValue = (callback) => callback(state);

    return (
        <PortfolioContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </PortfolioContext.Provider>
    );
};

PortfolioOverviewProvider.propTypes = {
    options: PropTypes.shape({
        id: PropTypes.number,
        clientId: PropTypes.number,
        portfolio: PropTypes.shape({
            loadInitially: PropTypes.bool,
        }),
        instrument: PropTypes.shape({
            adaptData: PropTypes.func,
            loadInitially: PropTypes.bool,
        }),
        instrumentPerformance: PropTypes.shape({
            adaptData: PropTypes.func,
            loadInitially: PropTypes.bool,
            currencyId: PropTypes.number.isRequired,
            filters: PropTypes.shape({
                Days: PropTypes.number,
                Years: PropTypes.number,
            }),
        }).isRequired,
    }).isRequired,
};

PortfolioOverviewProvider.defaultProps = {};

export default PortfolioOverviewProvider;
