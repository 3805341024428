import { isArray, isEmpty, isObject } from 'lodash';

export const resourceAdapt = (resources) => {
    try {
        if (resources != null
            && isObject(resources)
            && !isEmpty(resources)
            && resources.values != null
            && isArray(resources.values)
            && !isEmpty(resources.values)) {
            return resources.values.reduce((accumulator, currentValue) => {
                if (currentValue?.name) {
                    accumulator[currentValue.name] = currentValue.value;
                }

                return accumulator;
            }, {});
        }

        return {};
    } catch {
        return {};
    }
};
