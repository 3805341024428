import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import {
    Header as HeaderUILibrary,
    Navigation,
    NavigationLink as NavLink,
} from 'ui-library';
import { Logo } from 'components/Logo';
import ThemeToggle from 'components/ThemeToggle';

import './Header.css';

export const Header = ({
    isAuthenticated,
    onRightButtonClick,
    pathname,
    unreadMessages: hasChanges,
    profile,
    notifications,
}) => {
    const { t } = useTranslation();
    const [profileOpen, setProfileOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleNavigation = () => {
        setIsOpen(!isOpen);
    };
    const closeNavigation = () => {
        setIsOpen(false);
    };
    const { allNotifications, onReadNotification, errorNotifications } = notifications;
    const current = useMemo(() => pathname.split('/')[1], [pathname]);
    const currentSub = useMemo(() => pathname.split('/')[2], [pathname]);
    const navigation = useMemo(
        () => [
            {
                title: t('MainMenu.DashboardMenuItem'),
                link: '/dashboard',
                active: current === 'dashboard',
            },
            {
                title: t('MainMenu.PortfoliosMenuItem'),
                link: '/portfolios',
                active: current === 'portfolios',
            },
            {
                title: t('MainMenu.MessagesMenuItem'),
                link: '/messages',
                active: current === 'messages',
                hasChanges,
            },
            {
                title: t('MainMenu.Documents'),
                link: '/documents',
                active: current === 'documents',
            },
            {
                title: t('MainMenu.NewsMenuItem'),
                active: current === 'news',
                submenu: [
                    {
                        title: t('MainMenu.Announcements'),
                        link: '/news/announcements',
                        active: currentSub === 'announcements',
                    },
                    {
                        title: t('MainMenu.Research'),
                        link: '/news/research',
                        active: currentSub === 'research',
                    },
                    {
                        title: t('MainMenu.ExternalNews'),
                        link: '/news/external',
                        active: currentSub === 'external',
                    },
                ],
            },
            { title: t('MainMenu.Payments'), link: '/payments', active: current === 'payments' },
        ],
        [t, current, currentSub, hasChanges],
    );

    const profileInfo = useMemo(
        () => ({
            ...profile,
            withEdit: profile?.hasProducts,
            withAlert: true,
            notifications: {
                data: allNotifications,
                onReadNotification,
                emptyMessage: t('profileMenu.notifications.empty'),
                errorMessage: errorNotifications,
            },
            editText: t('dashboard.profile.editRiskProfile'),
            logoutText: t('dashboard.profile.logout'),
            onEdit: () => {
                setProfileOpen(false);
                history.push('/risk-profile');
            },
            onLogout: () => {
                setProfileOpen(false);
                onRightButtonClick();
            },

            withAdditionalButtons: [
                ...(profile?.hasProducts ? [{
                    onClick: () => {
                        setProfileOpen(false);
                        history.push('/details');
                    },
                    text: t('dashboard.profile.editKE'),
                    id: 'edit-ke-btn',
                }] : []),
                {
                    onClick: () => {
                        history.push('/change-password');
                    },
                    text: t('dashboard.profile.password'),
                    id: 'change-pw-btn',
                },
            ],
            additionalInfo: (
                <>
                    <b>
                        {t('dashboard.profile.riskProfile')}
                        {': '}
                    </b>
                    {profile.riskProfile}
                    <br />
                    <b>
                        {t(
                            profile.portfolios > 1
                                ? 'dashboard.profile.portfolios'
                                : 'dashboard.profile.portfolio',
                        )}
                        {': '}
                    </b>
                    {profile.portfolios}
                </>
            ),
            visible: profileOpen,
            onVisibleChange: setProfileOpen,
            footer: <ThemeToggle />,
        }), [
            profile,
            allNotifications,
            onReadNotification,
            t,
            errorNotifications,
            profileOpen,
            onRightButtonClick,
        ],
    );
    const navigationTablet = useMemo(
        () => (
            <Navigation isOpen={isOpen} onClose={closeNavigation}>
                <NavLink to="/dashboard" homeLink={current === 'dashboard'}>{t('MainMenu.DashboardMenuItem')}</NavLink>
                <NavLink to="/portfolios" homeLink={current === 'portfolios'}>{t('MainMenu.PortfoliosMenuItem')}</NavLink>
                <NavLink to="/messages" homeLink={current === 'messages'}>{t('MainMenu.MessagesMenuItem')}</NavLink>
                <NavLink to="/documents" homeLink={current === 'documents'}>{t('MainMenu.Documents')}</NavLink>
                <NavLink to="/news/announcements" homeLink={currentSub === 'announcements'}>{t('MainMenu.Announcements')}</NavLink>
                <NavLink to="/news/research" homeLink={currentSub === 'research'}>{t('MainMenu.Research')}</NavLink>
                <NavLink to="/news/external" homeLink={currentSub === 'external'}>{t('MainMenu.ExternalNews')}</NavLink>
                <NavLink to="/payments" homeLink={current === 'payments'}>{t('MainMenu.Payments')}</NavLink>
            </Navigation>
        ),
        [isOpen, current, t],
    );
    const leftButton = { type: isAuthenticated === true ? 'menu' : null, label: null };
    const rightButton = { type: isAuthenticated === true ? 'login' : null, label: null };

    return (
        <Fragment>
            <HeaderUILibrary
                title={t('login.title')}
                navigation={isAuthenticated && navigation}
                leftButton={leftButton}
                rightButton={rightButton}
                profileInfo={isAuthenticated && profileInfo}
                navigationTablet={navigationTablet}
                onLeftButtonClick={toggleNavigation}
                content={<Logo />}
            />
        </Fragment>
    );
};

Header.propTypes = {
    pathname: PropTypes.string.isRequired,
    onRightButtonClick: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    unreadMessages: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        portfolios: PropTypes.number,
        riskProfile: PropTypes.string,
        hasProducts: PropTypes.bool,
    }).isRequired,
    notifications: PropTypes.shape({
        allNotifications: PropTypes.arrayOf(PropTypes.shape),
        onReadNotification: PropTypes.func,
        errorNotifications: PropTypes.string,
    }).isRequired,
};

Header.defaultProps = {};

Header.displayName = 'Header';
