import React from 'react';
import { useTranslation } from 'react-i18next';
import LightLogoSvg from '../../customizations/logo_ISP-LUX.png';
import './Logo.css';

export const Logo = () => {
    const { t } = useTranslation();

    const themeLogo = LightLogoSvg;

    return (
        <div>
            <img
                src={themeLogo}
                alt={t('login.title')}
                className="logo-icon"
            />
        </div>
    );
};
