import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject,
} from 'constants/portfolioProducts';
import PortfolioAdvisory from './products/PortfolioAdvisory';
import PortfolioDiscretionary from './products/PortfolioDiscretionary';
import PortfolioExecutionOnly from './products/PortfolioExecutionOnly';

import './PortfolioOverview.css';

const PortfolioByProduct = generateObject({
    [ADVISORY]: PortfolioAdvisory,
    [DISCRETIONARY]: PortfolioDiscretionary,
    [EXECUTION_ONLY]: PortfolioExecutionOnly,
});

function PortfolioOverview(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const PortfolioPage = productId === undefined ? () => '' : PortfolioByProduct[productId] || PortfolioAdvisory;

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <PortfolioPage {...props} />
        </Preloader>
    );
}

PortfolioOverview.propTypes = {
    contactId: PropTypes.number.isRequired,
};

PortfolioOverview.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(PortfolioOverview);
