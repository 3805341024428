import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting } from 'locale';
import { INV_ALLOC_DATA_KEYS } from 'constants/constants';
import { adaptParentsAllocations } from '../adapters/adaptParentsAllocations';

export const useParentsAllocations = ({ isLoading, currency, clientId }) => {
    const { getFormattedCurrency } = useFormatting();
    const [errorAllocation, setError] = useState(null);
    const [isLoadingAllocation, setIsLoadingAllocation] = useState(true);
    const [investmentAllocation, setData] = useState({
        [INV_ALLOC_DATA_KEYS.withLookThroughtData]: {
            chart: [{
                data: {
                    pieSa: {}, bar: {}, list: {}, pie: {},
                },
            }],
        },
        [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]: {
            chart: [{
                data: {
                    pieSa: {}, bar: {}, list: {}, pie: {},
                },
            }],
        },
    });
    const { i18n, t } = useTranslation();
    const currencyParams = {
        page: 1,
        pageSize: 1000,
    };

    useEffect(() => {
        if (!isLoading && !!clientId) {
            setIsLoadingAllocation(true);
            Promise.all([
                ServiceManager.portfolioManagement('getPortfolioAllocation', [
                    clientId,
                    { language: i18n.language, includeLookThrough: false },
                ]),
                ServiceManager.commonService('getAssetClasses', [i18n.language]),
                ServiceManager.commonService('getSecurityTypes', [i18n.language]),
                ServiceManager.currenciesService('searchCurrencies', [currencyParams]),
                ServiceManager.commonService('getCountries', [i18n.language]),
                ServiceManager.commonService('getSectors', [i18n.language]),
                ServiceManager.portfolioManagement('getPortfolioAllocation', [
                    clientId,
                    { language: i18n.language, includeLookThrough: true },
                ]),
            ])
                .then((response) => response
                    .map((item, index) => (index !== 3 ? item.data : item.data?.Results)))
                .then((response) => {
                    try {
                        setData(
                            {
                                [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]:
                                 adaptParentsAllocations(
                                     response[0],
                                     response[1],
                                     response[2],
                                     response[3],
                                     response[4],
                                     response[5],
                                     t,
                                     getFormattedCurrency,
                                     currency,
                                 ),
                                [INV_ALLOC_DATA_KEYS.withLookThroughtData]:
                                 adaptParentsAllocations(
                                     response[6],
                                     response[1],
                                     response[2],
                                     response[3],
                                     response[4],
                                     response[5],
                                     t,
                                     getFormattedCurrency,
                                     currency,
                                 ),
                            },
                        );
                        setIsLoadingAllocation(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setData(
                        {
                            [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]:
                             adaptParentsAllocations(
                                 [],
                                 [],
                                 [],
                                 [],
                                 [],
                                 [],
                                 t,
                                 t,
                                 getFormattedCurrency,
                                 currency,
                             ),
                            [INV_ALLOC_DATA_KEYS.withLookThroughtData]:
                             adaptParentsAllocations(
                                 [],
                                 [],
                                 [],
                                 [],
                                 [],
                                 [],
                                 t,
                                 getFormattedCurrency,
                                 currency,
                             ),
                        },

                    );
                    handlerRequestCanceling(
                        HandlerError({ setError, setLoading: setIsLoadingAllocation }),
                    )(err);
                })
                .finally(() => {
                    setIsLoadingAllocation(false);
                });
        }
    }, [isLoading, i18n.language, currency, clientId]);

    return { investmentAllocation, isLoadingAllocation, errorAllocation };
};
