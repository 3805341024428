import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';
import HandlerError from '../errors/HandlerError';

const SEARCH_SECURITIES = gql`
    query SearchSecurities($length: Int!, $ids: [Int!]) {
        securities(
            take: $length,
            where: { id: { in: $ids } }
        ){
            items {
                id
                name
                isin
                type { name }
                currency { isoCode:threeLetterIsoCode }
                assetClass { id name }
            }
        }
    }
`;

export const useSecuritiesSearch = ({ adaptSecurities, productId, params }) => {
    const [errorSecurities, setError] = useState(null);
    const [securityIds, setSecurityIds] = useState({ ids: [] });
    const [assetClasses, setAssetClasses] = useState({ assetClasses: [] });
    const [isLoadingSecurities, setLoading] = useState(true);
    const { i18n: { language } } = useTranslation();

    const [getSecurities, { loading, error, data: securities }] = useLazyQuery(SEARCH_SECURITIES);
    const searchSecurities = useCallback(async () => {
        if (!params || !productId) return;

        setError(null);
        setLoading(true);

        try {
            const response = await SM.instrumentsService('searchSecurities', [productId, params, { language }]);

            setSecurityIds({
                ids: (response.data?.Results || []).map(({ Id }) => Id),
                total: response.data?.TotalCount,
            });
            setLoading(false);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);
        }
    }, [params, language, productId]);

    const getAssetClasses = useCallback(async () => {
        if (!language) return;

        setError(null);
        setLoading(true);

        try {
            const response = await SM.commonService('getAssetClasses', [language]);

            setAssetClasses({
                assetClasses: (response?.data || []),
            });
            setLoading(false);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);
        }
    }, [language]);

    useEffect(() => {
        searchSecurities();
    }, [searchSecurities]);

    useEffect(() => {
        getAssetClasses();
    }, [getAssetClasses]);

    useEffect(() => {
        if (securityIds.ids && params?.PageSize) {
            getSecurities({ variables: { ids: securityIds.ids, length: params?.PageSize } });
        }
    }, [params?.PageSize, getSecurities, securityIds.ids]);

    // eslint-disable-next-line max-len
    const dataSecurities = useMemo(() => adaptSecurities(securities?.securities?.items, assetClasses?.assetClasses),
        [adaptSecurities, securities, assetClasses]);
    const totalSecurities = useMemo(() => securityIds.total, [securityIds.total]);

    return {
        dataSecurities,
        totalSecurities,
        isLoadingSecurities: isLoadingSecurities || loading,
        errorSecurities: errorSecurities || error,
    };
};
