import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import SM from 'services/ServiceManager';
import { withCSContext } from 'services/ApolloClient';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { useSchema } from 'hooks/useSchema';
import { adaptClientInfo, getImgBase64Src } from '../adaptors/adaptClientInfo';
import useClientRiskCategory from './useClientRiskCategory';

const CLIENT_INFORMATION = gql`
    query ClientInformation {
        contact{
            id
            personalInformation {
                firstName
                lastName
                salutation { label value }
                displayName
            }
            relationshipInformation {
                isProspect
            }
            communicationMethods {
                primaryPhoneCountryCode { label value }
                primaryPhoneNumber
                primaryAddress {
                    primaryAddressLine1
                    primaryAddressLine2
                    primaryAddressLine3
                    primaryAddressLine4
                    primaryAddressLine5
                }
                primaryAddressCountry { label value }
            }
            investmentInformation {
                totalNumberOfPortfolios
                chosenRiskCategory { label value }
            }
        }
    }
`;

export const useGetClientInfo = (options) => {
    const { clientId, withAdditionalInfo } = options;
    const { t } = useTranslation();
    const { getFormattedPhoneNumber } = useFormatting();
    const { getSchemaLabelById } = useSchema();

    const [errorImg, setError] = useState(null);
    const [isLoadingImg, setLoading] = useState(false);
    const [img, setImg] = useState(null);

    const { loading: isLoading, error, data: dataClientRaw } = useQuery(CLIENT_INFORMATION, {
        context: withCSContext({ queryParams: { contactId: clientId } }),
    });
    const getClientAdditional = useCallback(async () => {
        if (withAdditionalInfo) {
            try {
                setError(null);
                setLoading(true);

                // const response = await SM.clientService('getClientPicture', [clientId]);
                const response = await SM.CustomLayerService('clientServicesGetContactProfilePicture', [clientId]);

                setImg(getImgBase64Src(response.data?.imageContentBase64));
                setLoading(false);
            } catch (err) {
                handlerRequestCanceling(HandlerError({ setError, setLoading }))(err);
            }
        }
    }, [clientId, withAdditionalInfo]);

    useEffect(() => {
        getClientAdditional();
    }, [getClientAdditional]);

    // user chosen risk profile is not live data from graphQL. hence make separate call
    const { clientRiskCategory, hasRiskProfile } = useClientRiskCategory(clientId);

    const dataClient = useMemo(() => adaptClientInfo({
        data: dataClientRaw?.contact,
        t,
        getFormattedPhoneNumber,
        getSchemaLabelById,
        ChosenRiskCategory: clientRiskCategory?.ChosenRiskCategory,
    }), [dataClientRaw, getFormattedPhoneNumber, getSchemaLabelById, t, clientRiskCategory]);

    const data = useMemo(() => ({
        ...dataClient, Img: img,
    }), [dataClient, img]);

    return {
        data,
        error: error || errorImg,
        isLoading: isLoading || isLoadingImg,
        hasRiskProfile,
    };
};
