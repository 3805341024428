import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Infobox, Paragraph, Title } from 'ui-library';
import history from 'services/history';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {
    chosenRiskSelector,
    formDataSelector,
    questionnaireSelector,
    riskCategoriesSelector,
    useRiskProfileSelector,
} from 'domain/RiskProfile';
import ChangeRiskModal from 'components/ChangeRiskModal';
import ReviewSection from 'components/ReviewSection';
import { useWithInvestmentOverview } from '../../../Dashboard/hooks/useWithInvestmentOverview';
import { RiskProfileTemplate } from '../../templates/RiskProfileTemplate';
import ReviewPortfolios from '../../components/ReviewPortfolios/ReviewPortfolios';
import './RiskProfileReview.css';

const RiskProfileReview = () => {
    const clientId = useSelector(contactIdSelector);
    const { t } = useTranslation();
    const [risksSelected, setRiskSelected] = useState();
    const [showRiskChangingModal, setShowRiskChangingModal] = useState(false);

    // ProductId selection
    const {
        data: dataOverview, isLoading: isLoadingOverview, error: errorOverview,
    } = useWithInvestmentOverview({ clientId });

    // Risks Profile Domain
    const {
        data, isLoading, error, getQuestionnaire,
        dataCalculate, isLoadingCalculate, errorCalculate, calculateRisk,
    } = useRiskProfileSelector(questionnaireSelector);
    const {
        data: dataRisks, isLoading: isLoadingRisks, error: errorRisks,
        getRiskCategories,
    } = useRiskProfileSelector(riskCategoriesSelector);
    const {
        selectedRisk: selectedRiskSS, saveSelectedRisk, clearSelectedRisk,
        clearData,
    } = useRiskProfileSelector(formDataSelector);
    const {
        isLoading: isSending, error: errorSetChosenRisk, setChosenRisk,
    } = useRiskProfileSelector(chosenRiskSelector);

    // Data
    const productId = useMemo(() => dataOverview?.productIds?.[0], [dataOverview]);
    const riskObj = useMemo(() => (dataRisks || [])
        .reduce((acc, risk) => ({ ...acc, [risk.Id]: risk }), {}), [dataRisks]);
    const maxAvailableRisk = useMemo(() => Math.max(1, dataCalculate?.RiskCategory?.Id),
        [dataCalculate]);
    const availableRisks = useMemo(() => (dataRisks || [])
        .filter(({ Id }) => Id <= maxAvailableRisk), [dataRisks, maxAvailableRisk]);

    // Callbacks
    const onRedo = (idx) => {
        history.push(`/risk-profile/${idx}`, { redoStep: true });
    };
    const onChangeRisk = () => {
        setShowRiskChangingModal(true);
    };
    const onChangeRiskConfirm = (currentRisk) => {
        saveSelectedRisk(currentRisk || risksSelected);
        setShowRiskChangingModal(false);
    };
    const onChangeRiskCancel = () => {
        setShowRiskChangingModal(false);
    };
    const onFinishClick = useCallback(async () => {
        await setChosenRisk(risksSelected);
        clearData();
        clearSelectedRisk();
        history.push('/dashboard');
    }, [clearData, clearSelectedRisk, risksSelected, setChosenRisk]);


    // Effects
    useEffect(() => {
        global.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        if (productId && !data) getQuestionnaire({ productId });
    }, [data, getQuestionnaire, productId]);
    useEffect(() => {
        if (productId) calculateRisk({ productId });
    }, [calculateRisk, productId]);
    useEffect(() => {
        if (productId) getRiskCategories({ productId });
    }, [getRiskCategories, productId]);
    useEffect(() => {
        if (selectedRiskSS && dataCalculate?.RiskCategory?.Id < selectedRiskSS) {
            setRiskSelected(dataCalculate?.RiskCategory?.Id);
        } else {
            setRiskSelected(selectedRiskSS || dataCalculate?.RiskCategory?.Id);
        }
    }, [selectedRiskSS, dataCalculate]);

    return (
        <RiskProfileTemplate groups={data?.Groups} currentGroup={data?.Groups?.length}>
            <Preloader
                isLoading={isLoading || isLoadingOverview || isLoadingCalculate || isLoadingRisks}
                error={error || errorOverview || errorCalculate || errorRisks}
            >
                <EmptyContent data={data} text={t('dashboard.noData')}>
                    <Title type={2}>{t('onBoarding.riskProfileReview')}</Title>
                    <div className="stepper-content-review">
                        <div className="risk-profile-review">
                            <div className="header-with-description">
                                <Title type={2}>{data?.Name}</Title>
                                <Paragraph type="secondary">{t('riskProfileReview.riskProfile.description')}</Paragraph>
                            </div>
                            <ChangeRiskModal
                                visible={showRiskChangingModal}
                                defaultRisk={dataCalculate?.RiskCategory?.Id}
                                selectedRisk={risksSelected}
                                availableRisks={availableRisks}
                                onChangeRisk={setRiskSelected}
                                onOk={onChangeRiskConfirm}
                                onCancel={onChangeRiskCancel}
                            />
                            {(dataCalculate?.Groups || []).map((group, idx) => (
                                <ReviewSection
                                    title={group.Name}
                                    description={riskObj?.[group.RiskCategory.Id]?.Description}
                                    riskCategoryId={group.RiskCategory.Id}
                                    riskCategoryName={group.RiskCategory.Name}
                                    onRedo={() => onRedo(idx)}
                                />
                            ))}
                            <ReviewSection
                                title={t('riskProfile.riskProfile')}
                                description={riskObj?.[risksSelected]?.Description}
                                riskCategoryId={risksSelected}
                                riskCategoryName={riskObj?.[risksSelected]?.Name}
                                onChangeRisk={onChangeRisk}
                            />
                        </div>
                        <ReviewPortfolios riskName={riskObj?.[risksSelected]?.Name} />
                    </div>
                    <ButtonsBlockRow
                        primaryButton={{
                            text: t('riskProfile.finish'),
                            loading: isSending,
                            onClick: onFinishClick,
                        }}
                    >
                        {errorSetChosenRisk && (
                            <Infobox accent>{errorSetChosenRisk.message}</Infobox>
                        )}
                    </ButtonsBlockRow>
                </EmptyContent>
            </Preloader>
        </RiskProfileTemplate>
    );
};

RiskProfileReview.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            group: PropTypes.string,
        }),
    }).isRequired,
};

RiskProfileReview.defaultProps = {
};

export default RiskProfileReview;
