import React, {
    createContext, memo, useEffect, useState,
} from 'react';
import ServiceManager from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialContext = {
    assetClasses: undefined,
    securityTypes: undefined,
    currencies: undefined,
    countries: undefined,
    sectors: undefined,
};

export const CommonAllocationContext = createContext(initialContext);

const CommonAllocationDataProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [assetClasses, setAssetClasses] = useState(undefined);
    const [securityTypes, setSecurityTypes] = useState(undefined);
    const [currencies, setCurrencies] = useState(undefined);
    const [countries, setCountries] = useState(undefined);
    const [sectors, setSectors] = useState(undefined);
    const [isLoading, setIsloading] = useState(true);
    const currencyParams = {
        page: 1,
        pageSize: 1000,
    };

    useEffect(() => {
        Promise.all([
            ServiceManager.commonService('getAssetClasses', [i18n.language]),
            ServiceManager.commonService('getSecurityTypes', [i18n.language]),
            ServiceManager.currenciesService('searchCurrencies', [currencyParams]),
            ServiceManager.commonService('getCountries', [i18n.language]),
            ServiceManager.commonService('getSectors', [i18n.language]),
        ])
            .then((response) => response
                .map((item, index) => (index !== 2 ? item.data : item.data?.Results)))
            .then((response) => {
                setAssetClasses(response[0]);
                setSecurityTypes(response[1]);
                setCurrencies(response[2]);
                setCountries(response[3]);
                setSectors(response[4]);
                setIsloading(false);
            })
            .catch((err) => {
                handlerRequestCanceling()(err);
            });
    }, [i18n.language]);

    return (
        <CommonAllocationContext.Provider value={{
            assetClasses, securityTypes, currencies, countries, sectors, isLoading,
        }}
        >
            {children}
        </CommonAllocationContext.Provider>
    );
};

CommonAllocationDataProvider.propTypes = {
    children: PropTypes.func.isRequired,
};

CommonAllocationDataProvider.defaultProps = {};

export const CommonAllocationProvider = memo(CommonAllocationDataProvider);
