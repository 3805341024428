import ServiceManager from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { groupObjectById } from 'adaptors/groupObjectById';
import { INV_ALLOC_DATA_KEYS } from 'constants/constants';
import { adaptInvestmentAllocation } from '../adapters/adaptAllocation';

const DEFAULT_VALUE = {
    [INV_ALLOC_DATA_KEYS.withLookThroughtData]: {
        chart: [{
            data: {
                pieSa: {}, bar: {}, list: {}, pie: {},
            },
        }],
    },
    [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]: {
        chart: [{
            data: {
                pieSa: {}, bar: {}, list: {}, pie: {},
            },
        }],
    },
};

export const useAllocationsData = ({
    clientId,
    portfolioId,
    isLoading,
    currency,
}) => {
    const [errorAllocation, setError] = useState(null);
    const [isLoadingAllocation, setLoadingAllocation] = useState(true);
    const [investmentAllocation, setData] = useState(DEFAULT_VALUE);
    const { i18n, t } = useTranslation();
    const currencyParams = {
        page: 1,
        pageSize: 1000,
    };

    useEffect(() => {
        setLoadingAllocation(true);

        if (!isLoading) {
            Promise.all([
                ServiceManager.portfolioManagement(
                    'getContactsPortfolioAllocation',
                    [
                        clientId,
                        portfolioId,
                        { language: i18n.language, includeLookThrough: false },
                    ],
                ),
                ServiceManager.commonService('getAssetClasses', [
                    i18n.language,
                ]),
                ServiceManager.commonService('getSecurityTypes', [
                    i18n.language,
                ]),
                ServiceManager.currenciesService('searchCurrencies', [
                    currencyParams,
                ]),
                ServiceManager.commonService('getCountries', [i18n.language]),
                ServiceManager.commonService('getSectors', [i18n.language]),
                ServiceManager.portfolioManagement(
                    'getContactsPortfolioAllocation',
                    [
                        clientId,
                        portfolioId,
                        { language: i18n.language, includeLookThrough: true },
                    ],
                ),
            ])
                .then((response) => response
                    .map((item, index) => (index !== 3 ? item.data : item.data?.Results)))
                .then((response) => {
                    try {
                        const listNames = response
                            .slice(1, 6)
                            .map((array) => groupObjectById(array));

                        setData({
                            [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]: {
                                chart: adaptInvestmentAllocation(
                                    response[0],
                                    listNames,
                                    currency,
                                    t,
                                ),
                                currency,
                            },
                            [INV_ALLOC_DATA_KEYS.withLookThroughtData]: {
                                chart: adaptInvestmentAllocation(
                                    response[6],
                                    listNames,
                                    currency,
                                    t,
                                ),
                                currency,
                            },
                        });
                        setLoadingAllocation(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setData(DEFAULT_VALUE);
                    handlerRequestCanceling(
                        HandlerError({
                            setError,
                            setLoading: setLoadingAllocation,
                        }),
                    )(err);
                });
        }
    }, [isLoading, currency, clientId, portfolioId, i18n.language]);

    return {
        investmentAllocations:
            investmentAllocation,
        isLoading: isLoadingAllocation,
        error: errorAllocation,
    };
};
