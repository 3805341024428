import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginLogoSvgDark from '../../customizations/logo_ISP-LUX.png';
import './AuthWrapper.css';

const AuthWrapper = ({ children }) => {
    const { t } = useTranslation();

    return (
        <div className="container container-small detail_page Auth">
            <div className="detail_content content-pane">
                <div className="application-title">
                    <Link to="/login">
                        <img
                            src={LoginLogoSvgDark}
                            alt={t('login.title')}
                        />
                    </Link>
                </div>
                <div className="content-pane-wrapper">
                    {children}
                </div>
            </div>
        </div>
    );
};

AuthWrapper.defaultProps = {
    children: '',
};

export default AuthWrapper;
