import {
    useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNotificationConext } from 'additiv-react-signalr';
import { formatDate, getDateFormat } from '../utils/datetime';
import { useLocale } from '../locale';

export const useNotifications = () => {
    const { t, i18n } = useTranslation();
    const { locale } = useLocale();
    const format = useMemo(() => getDateFormat(locale), [locale]);
    const errorMessage = useMemo(() => t('profileMenu.notifications.error'), [t, i18n.language]);
    const {
        notifications: allNotifications,
        readNotification: onReadNotification,
        error,
    } = useNotificationConext();

    return {
        onReadNotification,
        // eslint-disable-next-line max-len
        allNotifications: allNotifications.map((item) => ({ ...item, date: formatDate(item.date, format) })),
        errorNotifications: error && errorMessage,
    };
};
