import React from 'react';
import {
    CellPDFView, CellLink, CellStatus, CellWithMoreActions,
} from 'components/renderers';
import { sorterByDate, sorterString } from 'utils/sorting';
import { Button } from 'ui-library';
import { DocumentUpload } from './components/DocumentUpload';

export default (t, isMobileSize, clientId, setUploadedId) => [
    {
        key: 'nameCol',
        title: t('clientDashboard.documents.name'),
        width: 200,
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('nameCol.value'),
    },
    {
        key: 'type',
        title: t('clientDashboard.documents.type'),
        sorter: sorterString('type'),
        width: 180,
        hidden: isMobileSize,
    },
    {
        key: 'category',
        title: t('clientDashboard.documents.category'),
        sorter: sorterString('category'),
        width: 180,
        hidden: isMobileSize,
    },
    {
        key: 'createDate',
        title: t('clientDashboard.documents.createDate'),
        width: 160,
        minWidth: 60,
        sorter: sorterByDate('fullDate'),
        sortable: true,
        className: 'table-cell__date',
    },
    {
        key: 'contractId',
        title: t('clientDashboard.documents.accountPortfolio'),
        sorter: sorterString('contractId.label'),
        render: CellLink,
        sortable: true,
        width: 200,
        hidden: isMobileSize,
    },
    {
        key: 'status',
        title: 'Status',
        sorter: sorterString('status.text'),
        render: CellStatus,
        sortable: true,
        width: 160,
        minWidth: 60,
    },
    {
        key: 'actions',
        title: 'Actions',
        render: (data) => data?.showUpload && (
            <DocumentUpload contactId={clientId} onUpload={setUploadedId} item={data?.item} />
        ),
        width: 30,
        minWidth: 30,
        fixed: 'right',
    },
    {
        key: 'icon',
        title: '',
        titleType: 'columns',
        width: 30,
        minWidth: 30,
    },
];
