import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { stepTitles as getStepTitle } from 'domain/OnBoarding/constants';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';

export const useStepsByProduct = ({ step, chosenProductName, missingStep }) => {
    const { t } = useTranslation();
    const stepTitles = useMemo(() => getStepTitle(t), [t]);

    // Constant steps for products
    const baseProgressBarSteps = useMemo(() => ({
        product: stepTitles.product,
        riskAppetite: stepTitles.riskAppetite,
        knowledgeExperience: stepTitles.knowledgeExperience,
        goal: stepTitles.goal,
        review: stepTitles.review,
        completion: stepTitles.completion,
    }), [stepTitles]);
    const ExOProgressBarSteps = useMemo(() => ({
        product: stepTitles.product,
        knowledgeExperience: stepTitles.knowledgeExperience,
        portfolioSetup: stepTitles.portfolioSetup,
        review: stepTitles.review,
        completion: stepTitles.completion,
    }), [stepTitles]);

    // Current steps
    const progressBarSteps = useMemo(() => generateObject({
        [ADVISORY]: baseProgressBarSteps,
        [DISCRETIONARY]: baseProgressBarSteps,
        [EXECUTION_ONLY]: ExOProgressBarSteps,
        [DEPOSITS]: baseProgressBarSteps,
    }).getByName(chosenProductName),
    [baseProgressBarSteps, ExOProgressBarSteps, chosenProductName]);
    const progressBarCurrent = useMemo(() => generateObject({
        [ADVISORY]: step - 1,
        [DISCRETIONARY]: step - 1,
        [EXECUTION_ONLY]: step - 2,
        [DEPOSITS]: step - 1,
    }).getByName(chosenProductName), [step, chosenProductName]);
    const missingStepTitle = useMemo(() => missingStep && stepTitles[missingStep],
        [stepTitles, missingStep]);

    return {
        progressBarSteps,
        progressBarCurrent,
        missingStepTitle,
    };
};
