import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import { useFormatting } from 'locale';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { getFirstDayOfUnit, getFirstDayOfYear } from 'utils/datetime';
import { adaptCashFlows } from '../adapters/adaptCashFlows';
import { getPreviousMonthWithPreviousDay, subtractUnits } from '../../../utils/datetime';

const initialState = {
    data: [],
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useCashFlows = (options = {}) => {
    const {
        clientId,
        portfolioId,
        adaptData = adaptCashFlows,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    // Callbacks
    const getCashFlows = useCallback(async ({ adaptOptions } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const params = { language };
            const { data: accounts } = await SM.portfolioManagement('getPortfolioAccounts', [clientId, portfolioId, params]);
            const { data: flatPortfolioData } = await SM.dataService('getFlatPortfolioData', [clientId]);
            const { data: { Values: lastMonthValues } } = await SM.portfolioManagement(
                'getPortfolioDetailsPerformance',
                [
                    portfolioId,
                    clientId,
                    {
                        language,
                        from: getPreviousMonthWithPreviousDay(),
                        to: getPreviousMonthWithPreviousDay(),
                    },
                ],
            );
            const { data: { Values: lastYearValues } } = await SM.portfolioManagement(
                'getPortfolioDetailsPerformance',
                [
                    portfolioId,
                    clientId,
                    {
                        language,
                        from: subtractUnits(getFirstDayOfYear(), 1, 'day'),
                        to: subtractUnits(getFirstDayOfYear(), 1, 'day'),
                    },
                ],
            );

            const flatPortfolio = JSON.parse(flatPortfolioData.Data);
            const {
                Values: { KPI: KPIValues, MarketValue: marketValue },
            } = flatPortfolio.Portfolios.find((portfolio) => portfolioId === portfolio.Id);


            let cashAccountId;
            let custodyAccountId;

            try {
                cashAccountId = accounts.find((item) => item?.Account.Type.Id === 1)?.Account.Id;
                custodyAccountId = accounts.find((item) => item?.Account.Type.Id === 2)?.Account.Id;
            } catch (err) {
                throw new AdapterError(err);
            }

            const monthFrom = getFirstDayOfUnit('month');
            const YTDFrom = getFirstDayOfYear();

            // Deposits (cash)
            const paramsDeposit = {
                SecurityType: 'All',
                Page: 1,
                PageSize: 100,
                AccountId: cashAccountId,
                TransactionType: 'Credit',
            };
            const paramsDepositMonth = { ...paramsDeposit, From: monthFrom };
            const paramsDepositYTD = { ...paramsDeposit, From: YTDFrom };

            // Transfer In (positions)
            const paramsTransferIn = {
                SecurityType: 'All',
                Page: 1,
                PageSize: 100,
                AccountId: custodyAccountId,
                TransactionType: 'Credit',
            };
            const paramsTransferInMonth = { ...paramsTransferIn, From: monthFrom };
            const paramsTransferInYTD = { ...paramsTransferIn, From: YTDFrom };

            // Withdrawals (cash)
            const paramsWithdrawals = {
                SecurityType: 'All',
                Page: 1,
                PageSize: 100,
                AccountId: cashAccountId,
                TransactionType: 'Debit',
            };
            const paramsWithdrawalsMonth = { ...paramsWithdrawals, From: monthFrom };
            const paramsWithdrawalsYTD = { ...paramsWithdrawals, From: YTDFrom };

            // Transfer Out (positions)
            const paramsTransferOut = {
                SecurityType: 'All',
                Page: 1,
                PageSize: 100,
                AccountId: custodyAccountId,
                TransactionType: 'Debit',
            };
            const paramsTransferOutMonth = { ...paramsTransferOut, From: monthFrom };
            const paramsTransferOutYTD = { ...paramsTransferOut, From: YTDFrom };

            // Call services in parallel
            const [
                { data: depositMonth }, { data: depositYTD }, { data: depositSI },
                { data: transferInMonth }, { data: transferInYTD }, { data: transferInSI },
                { data: withdrawalsMonth }, { data: withdrawalsYTD }, { data: withdrawalsSI },
                { data: transferOutMonth }, { data: transferOutYTD }, { data: transferOutSI },
            ] = await Promise.all([
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsDepositMonth]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsDepositYTD]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsDeposit]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsTransferInMonth]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsTransferInYTD]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsTransferIn]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsWithdrawalsMonth]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsWithdrawalsYTD]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsWithdrawals]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsTransferOutMonth]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsTransferOutYTD]),
                SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, paramsTransferOut]),
            ]);

            try {
                const adapted = adaptData({
                    accounts,
                    currency: adaptOptions?.currency,
                    historicalPerformance: adaptOptions?.historicalPerformance,
                    depositMonth,
                    depositYTD,
                    depositSI,
                    transferInMonth,
                    transferInYTD,
                    transferInSI,
                    withdrawalsMonth,
                    withdrawalsYTD,
                    withdrawalsSI,
                    transferOutMonth,
                    transferOutYTD,
                    transferOutSI,
                    t,
                    getFormattedNumber,
                    KPIValues,
                    marketValue,
                    beginLastMonth: lastMonthValues[0]?.Amount || 0,
                    beginLastYear: lastYearValues[0]?.Amount || 0,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({
                    type: 'setDataRaw',
                    payload: {
                        accounts,
                        currency: adaptOptions?.currency,
                        historicalPerformance: adaptOptions?.historicalPerformance,
                        depositMonth,
                        depositYTD,
                        depositSI,
                        transferInMonth,
                        transferInYTD,
                        transferInSI,
                        withdrawalsMonth,
                        withdrawalsYTD,
                        withdrawalsSI,
                        transferOutMonth,
                        transferOutYTD,
                        transferOutSI,
                    },
                });
                dispatch({ type: 'setIsLoading', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, clientId, portfolioId, adaptData, t, getFormattedNumber]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getCashFlows();
    }, [getCashFlows, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getCashFlows,
    };
};
