import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { PageNotFound } from 'pages/pages/404';
import ChangeStrategyAdvisory from './products/ChangeStrategyAdvisory';
import ChangeStrategyDiscretionary from './products/ChangeStrategyDiscretionary';
import './ChangeStrategy.css';
import withActionAccess from '../../hocs/withActionAccess';

const ChangeStrategyByProduct = generateObject({
    [ADVISORY]: ChangeStrategyAdvisory,
    [DISCRETIONARY]: ChangeStrategyDiscretionary,
    [EXECUTION_ONLY]: PageNotFound,
    [DEPOSITS]: ChangeStrategyAdvisory,
});

function ChangeStrategy(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const ChangeStrategyPage = ChangeStrategyByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <ChangeStrategyPage {...props} />
        </Preloader>
    );
}

ChangeStrategy.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ChangeStrategy.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(withActionAccess(ChangeStrategy));
