import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    ContentBox, Title, Button,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { getClassNames } from 'utils';
import { trimLongString } from 'utils/formatting';
import { CellStatusBadge, CellWithMoreActions, CellPercent } from 'components/renderers';
import { ButtonLink } from 'components/ButtonLink';
import './PortfoliosCard.css';

const PortfoliosCard = ({
    Name: { label, link, disabled }, Id, Performance, Profile, Value, Status, Actions, Product,
}) => {
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    const className = getClassNames({
        'PortfoliosCard-disabled': disabled,
    });

    const PerformanceValue = useCallback(
        () => CellPercent(getFormattedNumber)(Performance),
        [Performance],
    );

    return (
        <ContentBox className={`PortfoliosCard ${className}`}>
            <div className="portfolios-card-name">
                <Title type={2}>
                    <Link to={link}>{trimLongString(label, 10)}</Link>
                </Title>
                <CellStatusBadge {...Status} />
            </div>

            <div className="portfolios-card-value">
                <Title type={2}>{Value}</Title>
                {Performance && (
                    <div className="portfolios-card-performance">
                        <PerformanceValue />
                    </div>
                )}
            </div>

            <div className="portfolios-card-details">
                <div className="portfolios-card-profile">
                    {Profile && (
                        <div>
                            <span className="profile-title">{t('dashboard.profile.title')}</span>
                            {Profile}
                        </div>
                    )}
                    <div>
                        <span className="product-title">{t('portfolios.columns.product')}</span>
                        {Product}
                    </div>
                </div>

                <div className="portfolios-card-actions">
                    <ButtonLink
                        type="secondary"
                        size="small"
                        className="portfolios-card-details-btn"
                        id={`portfolio_card_details_${Id}`}
                        href={link()}
                    >
                        {t('dashboard.profile.details')}
                    </ButtonLink>

                    {(Actions?.actions && Actions?.actions.length > 0) && (
                        <Button
                            type="secondary"
                            size="small"
                            id={`portfolio_card_actions_${Id}`}
                            className="portfolios-card-action-btn"
                            tabIndex={-1}
                        >
                            <CellWithMoreActions
                                {...Actions}
                                disabledAction={Actions.disabled}
                            />
                        </Button>
                    )}
                </div>
            </div>
        </ContentBox>
    );
};

PortfoliosCard.propTypes = {
    Name: PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.func,
        disabled: PropTypes.bool,
    }).isRequired,
    Id: PropTypes.number.isRequired,
    Performance: PropTypes.number,
    Profile: PropTypes.string.isRequired,
    Value: PropTypes.string.isRequired,
    Status: PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string,
        value: PropTypes.string,
    }),
    Actions: PropTypes.shape({
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                onClick: PropTypes.func,
            }),
        ),
        value: PropTypes.string,
        disabled: PropTypes.bool,
    }),
    Product: PropTypes.string,
};

PortfoliosCard.defaultProps = {
    Status: {},
    Actions: {},
    Product: null,
    Performance: null,
};
export default PortfoliosCard;
