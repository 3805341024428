import { validateStringData } from 'utils/formatting';
import { LIQUIDITY_TYPE } from 'constants/constants';
import { getParent } from 'utils';
import {
    isArray, isEmpty, isFinite, isNumber, isObject,
} from 'lodash';

export const adaptSecurities = (data, assetClasses, { baseUrl, baseUrlEnd, language }) => {
    const flatAssetClasses = flatArrayData(assetClasses, []);

    return (data || [])
        .map((item) => {
            const {
                assetClass, currency, id, isin, name, type,
            } = item;
            const assetClassName = assetClass?.name;
            const assetParentName = getAssetClassParent(flatAssetClasses, assetClass?.id, null);
            const typeName = type?.name;

            return {
                key: id,
                Name: {
                    id,
                    value: validateStringData(name),
                    label: validateStringData(name),
                    link: type?.name === LIQUIDITY_TYPE ? undefined : `${baseUrl}${id}${baseUrlEnd || ''}`,
                    maxLength: 33,
                    name: 'position',
                },
                id,
                Isin: validateStringData(isin),
                AssetClass: validateStringData(assetParentName),
                SubAssetClass: validateStringData(assetClassName),
                Currency: validateStringData(currency?.isoCode),
                Type: validateStringData(typeName),
            };
        });
};

const flatArrayData = (array, resultingArray) => {
    if (array == null || !isObject(array) || isEmpty(array)) {
        return null;
    }

    Object.keys(array).forEach((element) => {
        if (Object.prototype.hasOwnProperty.call(array, element)) {
            if (isObject(array[element]) || isArray(array[element])) {
                flatArrayData(array[element], resultingArray);
            } else if (array.Id != null && isNumber(array.Id) && isFinite(array.Id)
                && array.Name != null && !isEmpty(array.Name)) {
                if (!resultingArray.some((x) => x.Id === array.Id)) {
                    resultingArray.push({
                        Id: array.Id,
                        Name: array.Name,
                        ParentId: array.ParentId,
                    });
                }
            }
        }
    });

    return resultingArray;
};

const getAssetClassParent = (array, childAssetClassId, previousAssetClassName) => {
    if (array == null || !isArray(array) || isEmpty(array) || childAssetClassId == null
        || !isNumber(childAssetClassId) || !isFinite(childAssetClassId)) {
        return previousAssetClassName;
    }

    const assetClass = array.find((x) => x.Id === childAssetClassId);

    if (assetClass == null || !isObject(assetClass) || isEmpty(assetClass)) {
        return previousAssetClassName;
    }

    if (assetClass.ParentId != null
        && isNumber(assetClass.ParentId)
        && isFinite(assetClass.ParentId)) {
        return getAssetClassParent(array, assetClass.ParentId, assetClass.Name);
    }

    return assetClass.Name;
};
