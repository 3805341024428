import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Accordion, AccordionPanel as Panel,
    Button, Infobox, Tabs, TabPane, Table, Dropdown, Icon, MenuItem, Select,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Preloader from 'components/Preloader';
import { YTD } from 'filters/investementPerformanceFilters';
import InfoboxWithButton from 'components/InfoboxWithButton';
import Analysis from 'components/Analysis';
import {
    cashFlowsSelector,
    orderBookSelector,
    portfolioSelector,
    transactionsSelector,
    usePortfolioSelector,
    useTransactions,
} from 'domain/Portfolio';
import AccordionWrapper from 'components/AccordionWrapper';
import ContentBox from 'ui-library/dist/components/ContentBox';
import PageHeader from 'components/PageHeader';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { usePagination } from 'hooks/usePagination';
import { PORTFOLIO_BLOCKED_ID } from 'constants/portfolioStatuses';
import { useContactGroups } from 'hooks/useContactGroups';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { createUniqueKey } from 'utils';
import AllocationWithILT from 'components/AllocationWithILT/AllocationWithILT';
import CashFlows from '../components/CashFlows';
import Overview from '../components/Overview';
import Positions from '../components/Positions';
import Proposals from '../components/Proposals';
import Transactions from '../components/Transactions';
import TransactionsCash from '../components/TransactionsCash';
import { useProposals } from '../hooks/useProposals';
import { getOrdersColumns } from '../constants';
import { useAllocationsData } from '../hooks/useAllocationsData';

function PortfolioAdvisory(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId } },
        location: { state },
    } = props;
    const { t } = useTranslation();
    const { isMobile } = useWindowDimensions();


    const {
        data, isLoading, dataRaw, error,
    } = usePortfolioSelector(portfolioSelector);

    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        if (data?.productId) {
            getOrderBook({ adaptOptions: { productId: data?.productId } });
        }
    }, [data?.productId, getOrderBook]);

    const {
        investmentAllocations,
        isLoading: isAllocationLoading,
        error: allocationError,
    } = useAllocationsData({
        clientId, portfolioId: data.id, isLoading, currency: data.overview.currency,
    });

    // Hooks
    const {
        dataProposals, isLoadingProposals, errorProposals,
    } = useProposals(clientId, portfolioId);
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        positions: data.positions,
        positionsRaw: dataRaw.Positions,
        isLoading,
        benchmark,
        defaultFilter: YTD,
        isLoadingBenchmark,
        additionalOptions: { usePortfolioPerformance: true },
    });
    const {
        data: accessPolicyContacts,
        isLoading: isLoadingAccessPolicyContacts,
        error: errorAccessPolicyContacts,
    } = useContactGroups({ contactId: clientId, contactGroupId: data?.contactGroupId });

    // Proposals logic
    const proposal = useRef();
    const [activeKey, setActiveKey] = useState(['2', '3', '5']);
    const reviewProposals = useCallback(() => {
        if (!activeKey.includes('4')) setActiveKey((arr) => [...arr, '4']);
        // eslint-disable-next-line no-unused-expressions
        proposal.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
        });
    }, []);

    useEffect(() => {
        if (dataProposals.length > 0) setActiveKey(['1', '2', '3', '4', '5']);
    }, [dataProposals]);
    useEffect(() => {
        if (state?.scrollToProposals && proposal.current) {
            reviewProposals();
        }
    }, [state, proposal.current, reviewProposals]);

    // Transactions logic TR
    const { page: TRPage, onPaginationChange: TROnPaginationChange } = usePagination(3);
    const TROptionsParams = useMemo(() => ({
        page: TRPage,
        PageSize: 10,
        SecurityType: 'Instruments',
    }), [TRPage]);
    const { data: portfolio } = usePortfolioSelector(portfolioSelector);
    const {
        data: TRData, isLoading: TRIsLoading, error: TRError, getTransactions: TRGetTransactions,
    } = usePortfolioSelector(transactionsSelector);
    const TRAdaptOptions = useMemo(() => ({
        baseUrl: `/portfolios/${portfolioId}/position/${portfolio?.productId}`,
    }), [portfolio.productId, portfolioId]);

    useEffect(() => {
        TRGetTransactions({ optionsParams: TROptionsParams, adaptOptions: TRAdaptOptions });
    }, [TRGetTransactions, TROptionsParams, TRAdaptOptions]);

    // Transactions cash logic TRC
    const { page: TRCPage, onPaginationChange: TRCOnPaginationChange } = usePagination(3);
    const {
        data: TRCData,
        isLoading: TRCIsLoading,
        error: TRCError,
        getTransactions: TRCGetTransactions,
    } = useTransactions({ clientId, portfolioId });
    const TRCOptionsParams = useMemo(() => ({
        page: TRCPage,
        PageSize: 10,
        SecurityType: 'Liquidity',
    }), [TRCPage]);
    const TRCAdaptOptions = useMemo(() => ({
        page: TRCPage,
        PageSize: 10,
        baseUrl: `/portfolios/${portfolioId}/position/${portfolio?.productId}`,
    }), [TRCPage, portfolio.productId, portfolioId]);

    useEffect(() => {
        TRCGetTransactions({ optionsParams: TRCOptionsParams, adaptOptions: TRCAdaptOptions });
    }, [TRCGetTransactions, TRCOptionsParams, TRCAdaptOptions]);

    // Cash flows CF
    const {
        data: CFData,
        isLoading: CFIsLoading,
        error: CFError,
        getCashFlows: CFGetCashFlows,
    } = usePortfolioSelector(cashFlowsSelector);
    const CFAdaptOptions = useMemo(() => ({
        historicalPerformance: portfolio?.HistoricalPerformance?.[0]?.Values,
    }), [portfolio]);

    useEffect(() => {
        if (CFAdaptOptions?.historicalPerformance) {
            CFGetCashFlows({ adaptOptions: CFAdaptOptions });
        }
    }, [CFGetCashFlows, CFAdaptOptions]);

    // Renderers
    const renderInfobox = () => (
        <InfoboxWithButton buttonText={t('dashboard.review')} buttonProps={{ onClick: reviewProposals }}>
            {t('portfolio.newProposalMessage')}
        </InfoboxWithButton>
    );

    const isBlocked = useMemo(
        () => data?.portfolioStatusId === PORTFOLIO_BLOCKED_ID,
        [data?.portfolioStatusId],
    );

    const disableAction = useMemo(
        () => isLoadingOrderBook || orderBook || isBlocked,
        [isLoadingOrderBook, orderBook, data?.portfolioStatusId],
    );

    const portfolioActions = useMemo(() => [{
        text: `${t('portfolios.rebalance')}`,
        onClick: () => history.push(`/portfolios/${portfolioId}/actions/rebalance`),
    }, {
        text: `${t('portfolios.quickEdit')}`,
        onClick: () => history.push(`/portfolios/${portfolioId}/actions/quick-edit`),
    }, {
        text: `${t('portfolios.modify')}`,
        onClick: () => history.push(`/portfolios/${portfolioId}/actions/modify`),
    }, {
        text: `${t('portfolios.changeStrategy')}`,
        onClick: () => history.push(`/portfolios/${portfolioId}/actions/change-strategy`),
    }, {
        text: `${t('overview.changeModel')}`,
        onClick: () => history.push(`/portfolios/${portfolioId}/actions/change-model`),
    }], [portfolioId]);

    const portfolioActionOptions = portfolioActions.map((item, index) => ({
        value: index,
        label: item.text,
    }));
    const doPortfolioAction = useCallback((selectedIndex) => {
        portfolioActions[selectedIndex]?.onClick?.();
    }, [portfolioActions]);

    return (
        <ContentBox underline={false} className="single-portfolio">
            <Preloader
                isLoading={isLoading || isLoadingAccessPolicyContacts}
                error={error || errorOrderBook || errorAccessPolicyContacts}
            >
                {orderBook && <Infobox>{t('portfolios.orderBookMessage')}</Infobox>}
                {(!isBlocked && dataProposals?.length > 0) && renderInfobox()}
                <PageHeader
                    title={data?.title}
                    breadCrumbsCurrent={data.backTitle}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            label: t('portfolios.title'),
                        },
                    ]}
                    description={(accessPolicyContacts || []).map(
                        (item) => (
                            <div className="access-policy">
                                <span>{item?.contactName}</span>
                                <span>{(item?.accessPolicy != null ? `(${item?.accessPolicy})` : '')}</span>
                            </div>
                        ),
                    )}
                >
                    <div className="buttons-block" id="portfolioActions">
                        {!isMobile ? (
                            <Select
                                disabled={disableAction}
                                placeholder={`${t('portfolios.columns.actions')}`}
                                onChange={doPortfolioAction}
                                options={portfolioActionOptions}
                                width={166}
                            />
                        ) : (
                            <Dropdown
                                placement="bottomRight"
                                overlayClassName="mt-5"
                                title={(
                                    <Button
                                        disabled={disableAction}
                                        loading={isLoadingOrderBook}
                                        type="secondary"
                                        size="small"
                                        className="actionBox"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Icon role="button" tabIndex={0} type="action" />
                                    </Button>
                                )}
                            >
                                {
                                    portfolioActions && portfolioActions.map((action, index) => (
                                        <MenuItem
                                            key={createUniqueKey(index, action.text)}
                                            onClick={action.onClick}
                                        >
                                            {action.text}
                                        </MenuItem>
                                    ))
                                }
                            </Dropdown>
                        )}

                    </div>
                </PageHeader>
                <Overview
                    portfolioId={portfolioId}
                    productId={data?.productId}
                    data={data.overview}
                    disableChangeStrategy
                    disableChangeModel
                />
                <AccordionWrapper>
                    <Accordion activeKeys={activeKey} onChange={setActiveKey}>
                        <Panel
                            header={t('portfolios.allocation')}
                            key="2"
                            className="allocation"
                        >
                            <AllocationWithILT
                                data={investmentAllocations}
                                isLoading={isAllocationLoading}
                                error={allocationError}
                            />
                        </Panel>
                        <Panel
                            header={t('portfolios.analysis')}
                            key="3"
                            className="performance"
                        >
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                oneProjectionColumn
                                showSustainabilityTab
                            />
                        </Panel>
                        {!isBlocked && (
                            <Panel
                                header={t('portfolios.proposals')}
                                className="proposals"
                                key="4"
                                count={dataProposals.length || undefined}
                            >
                                <div ref={proposal}>
                                    <Proposals
                                        data={dataProposals}
                                        isLoading={isLoadingProposals}
                                        error={errorProposals}
                                    />
                                </div>
                            </Panel>
                        )}
                        <Panel
                            header={t('portfolios.positions')}
                            className="positions"
                            key="5"
                        >
                            <Positions
                                data={data.positions}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                        <Panel
                            header={t('portfolios.transactionsAndCashFlows')}
                            className="security-transactions"
                            key="6"
                        >
                            <Tabs className="transactions_cash-flows" defaultActiveKey="transactions">
                                <TabPane tab={t('portfolios.securityTransactions')} key="transactions">
                                    <Transactions
                                        isLoading={TRIsLoading}
                                        page={TRPage}
                                        onPaginationChange={TROnPaginationChange}
                                        data={TRData}
                                        error={TRError}
                                        portfolio={portfolio}
                                    />
                                </TabPane>
                                <TabPane tab={t('portfolios.cashTransactions')} key="cash-transactions">
                                    <TransactionsCash
                                        isLoading={TRCIsLoading}
                                        page={TRCPage}
                                        onPaginationChange={TRCOnPaginationChange}
                                        data={TRCData}
                                        error={TRCError}
                                        portfolio={portfolio}
                                    />
                                </TabPane>
                                <TabPane tab={t('portfolios.cashFlows')} key="cashFlows">
                                    <CashFlows
                                        isLoading={CFIsLoading}
                                        data={CFData}
                                        error={CFError}
                                        portfolio={portfolio}
                                    />
                                </TabPane>
                            </Tabs>
                        </Panel>
                        <Panel header={t('portfolios.pendingOrder')} className="pending-order" key="7">
                            <Table
                                className="positions-table"
                                data={orderBook || []}
                                columns={getOrdersColumns(t)}
                                expandedColumn="name"
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </AccordionWrapper>
            </Preloader>
        </ContentBox>
    );
}

PortfolioAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    location: PropTypes.shape({
        state: {
            fromReview: PropTypes.bool,
            scrollToProposals: PropTypes.bool,
        },
    }).isRequired,
};

PortfolioAdvisory.defaultProps = {};

export default PortfolioAdvisory;
