import React, { useMemo } from 'react';
import {
    OVERVIEW_SIZE_MEDIUM, OverviewItem, OverviewPanel,
} from 'components/Overview';
import { Column, Row } from 'ui-library';
import { TYPE_CURRENCY, TYPE_PERCENT } from 'utils/formatting';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EXECUTION_ONLY_ID } from '../../common/constants';

function Overview(props) {
    const {
        data, portfolioId, productId, disableChangeStrategy, disableChangeModel,
    } = props;
    const { t } = useTranslation();

    const isChangeStrategyHidden = productId === EXECUTION_ONLY_ID || disableChangeStrategy;
    const showStrategy = useMemo(() => productId !== EXECUTION_ONLY_ID, [productId]);
    const strategyRender = useMemo(() => {
        if (!showStrategy) return null;
        if (isChangeStrategyHidden) {
            return (
                <OverviewItem
                    title={t('overview.strategy')}
                    value={data.strategy}
                    size={OVERVIEW_SIZE_MEDIUM}
                />
            );
        }

        return (
            <OverviewItem
                title={t('overview.strategy')}
                value={data.strategy}
                linkLabel={t('overview.changeStrategy')}
                link={`${portfolioId}/actions/change-strategy`}
                size={OVERVIEW_SIZE_MEDIUM}
            />
        );
    }, [data.strategy, isChangeStrategyHidden, portfolioId, showStrategy, t]);
    const modelPortfolioRender = useMemo(() => {
        if (!showStrategy) return null;
        if (disableChangeModel) {
            return (
                <OverviewItem
                    title={t('overview.modelPortfolio')}
                    value={data.modelPortfolio}
                    size={OVERVIEW_SIZE_MEDIUM}
                />
            );
        }

        return (
            <OverviewItem
                title={t('overview.modelPortfolio')}
                value={data.modelPortfolio}
                linkLabel={t('overview.changeModel')}
                link={`${portfolioId}/actions/change-model`}
                size={OVERVIEW_SIZE_MEDIUM}
            />
        );
    }, [data.modelPortfolio, disableChangeModel, portfolioId, showStrategy, t]);

    return (
        <OverviewPanel>
            <Row>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('overview.portfolioValue')}
                        value={data.portfolioValue}
                        type={TYPE_CURRENCY}
                        currency={data.currency}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                    <OverviewItem
                        title={t('overview.product')}
                        value={data.product}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                </Column>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('overview.performanceSI')}
                        value={data.performance}
                        type={TYPE_PERCENT}
                        size={OVERVIEW_SIZE_MEDIUM}
                        colored
                    />
                    {strategyRender}
                </Column>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('overview.firstInvestment')}
                        value={data.firstInvestment}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                    {modelPortfolioRender}
                </Column>
            </Row>
        </OverviewPanel>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        portfolioValue: PropTypes.number,
        currency: PropTypes.string,
        strategy: PropTypes.string,
        modelPortfolio: PropTypes.string,
        product: PropTypes.string,
        performance: PropTypes.number,
        sharpRatio: PropTypes.number,
        expectedReturn: PropTypes.number,
        expectedRisk: PropTypes.number,
        firstInvestment: PropTypes.string,
    }),
    portfolioId: PropTypes.string.isRequired,
    productId: PropTypes.number.isRequired,
    disableChangeStrategy: PropTypes.bool,
    disableChangeModel: PropTypes.bool,
};

Overview.defaultProps = {
    data: {},
    disableChangeStrategy: false,
    disableChangeModel: false,
};

export default Overview;
