import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components/Footer';

export const NoHeaderTemplate = ({ children }) => (
    <Fragment>
        <main className="container">{children}</main>
        <Footer />
    </Fragment>
);

NoHeaderTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

NoHeaderTemplate.defaultProps = {};

NoHeaderTemplate.displayName = 'NoHeaderTemplate';
