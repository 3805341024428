import { dateTimeFormats } from 'locale';
import { isEmpty } from 'lodash';

export const adaptResearch = (options) => {
    const {
        data, security, securityPrice, securityPerformance, language, dataDocument,
    } = (options || {});
    const t = options?.t || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const itemData = JSON.parse(data?.data || '{}');
    const submissionData = itemData?.submissions?.length
        ? itemData?.submissions.find((itm) => itm.language === language)?.submission?.data
        : itemData?.submission?.data;

    const historicalDataLast = securityPrice?.length
        ? securityPrice[securityPrice?.length - 1]?.Date : new Date();

    const helpTextDate = getFormattedDate(
        historicalDataLast, {
            hour: dateTimeFormats.TWO_DIGIT,
            minute: dateTimeFormats.TWO_DIGIT,
            second: dateTimeFormats.TWO_DIGIT,
        },
    );

    return {
        id: data?.id,
        title: submissionData?.researchTitle,
        date: data?.createdDate,
        image: data?.imageUri,
        imageContent: submissionData?.pictureDescription,
        content: submissionData?.mainText,
        keyTakeaways: submissionData?.keyTakeaways?.split(/\r?\n/) || [],
        documentId: itemData?.documentId,
        researchDocumentLink: '',
        instrument: {
            logo: security?.logoUrl,
            name: security?.name,
            price: getFormattedNumber(security?.tradingPrice),
            currency: security?.currency?.isoCode,
            performance: ([...(securityPerformance || [])].pop()?.Perf || 0) * 100,
            // Remove hardCode AT-1542
            currentRating: {
                type: 'buy',
                text: t('position.buy'),
            },
            lastRating: {
                type: 'sell',
                text: t('position.sell'),
            },
        },
        document: {
            name: dataDocument?.name,
            blob: dataDocument?.content,
        },
        // Remove hardCode AT-1542
        invest: {
            title: security?.name != null && !isEmpty(security?.name) ? `${t('research.investIn')} ${security?.name} ${t('research.now')}` : null,
        },
        helpTextDate,
    };
};
