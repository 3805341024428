import React from 'react';
import { ContentBox, Table } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { portfoliosColumns, portfoliosMobileColumns } from 'constants/constants';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import PageHeader from 'components/PageHeader';
import PageSection from '../PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';

function Portfolios({
    data, isLoading, error, pageSize,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedNumber } = useFormatting();

    return (
        <PageSection>
            <ContentBox className="portfolios">
                <PageHeader title={t('portfolios.title')} />
                <div className="portfolios-table">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data} text={t('portfolios.noPortfoliosFound')}>

                            <Table
                                data={data}
                                columns={
                                    isMobileSize
                                        ? portfoliosMobileColumns(t, getFormattedNumber)
                                        : portfoliosColumns(t, getFormattedNumber)
                                }
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                    sortByLabel: t('headerControls.sortBy'),
                                }}
                                paging={{
                                    pageSize,
                                }}
                                fixedLastColumn
                            />
                        </EmptyContent>
                    </Preloader>
                </div>
            </ContentBox>
        </PageSection>
    );
}

Portfolios.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                Name: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
                Product: PropTypes.string,
                Profile: PropTypes.string,
                Value: PropTypes.string,
                Performance: PropTypes.number,
                Icon: PropTypes.string,
            }),
        ),
        PropTypes.shape({
            Name: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
            Product: PropTypes.string,
            Profile: PropTypes.string,
            Value: PropTypes.string,
            Performance: PropTypes.number,
            Icon: PropTypes.string,
        }),
    ]),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    pageSize: PropTypes.number,
};

Portfolios.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    pageSize: 9,
};

export default Portfolios;
