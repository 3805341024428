import { renderers } from 'ui-library';
import { CellSwitch, CellPercent, CellWithMoreActions } from 'components/renderers';
import { sorterByDate, sorterString, sorterNumber } from 'utils/sorting';

const { CellWithIcon } = renderers;

export const mapIdToProposalStatus = {
    1: 'proposals.created',
    2: 'proposals.awaitingFeedback',
    3: 'proposals.accepted',
    4: 'proposals.rejected',
    5: 'proposals.deleted',
};

export const cashFlowsColumns = (t, currency) => [
    {
        key: 'KeyValue',
        title: '',
        width: '25%',
    },
    {
        key: 'CurrentMonth',
        title: `${t('portfolios.cashFlowsColumns.currentMonth')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
    {
        key: 'YTD',
        title: `${t('portfolios.cashFlowsColumns.YTD')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
    {
        key: 'SinceInception',
        title: `${t('portfolios.cashFlowsColumns.sinceInception')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
];
export const cashFlowsRowMarginIdx = [1, 2, 3, 4, 6];

export const positionsColumns = (t, getFormattedNumber) => [
    {
        key: 'name',
        title: t('position.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
        render: CellSwitch,
        width: 250,
        minWidth: 250,
    },
    {
        title: t('position.columns.isin'),
        key: 'isin',
        hidden: true,
        sorter: sorterString('isin'),
    },
    {
        title: t('position.columns.type'),
        key: 'type',
        hidden: true,
        sorter: sorterString('type'),
    },
    {
        title: t('position.columns.units'),
        key: 'units',
        width: 75,
        minWidth: 75,
        className: 'text-right',
        sorter: sorterNumber('units'),
        hidden: true,
    },
    {
        title: t('position.columns.costPrice'),
        key: 'costPrice',
        className: 'text-right',
        width: 100,
        minWidth: 100,
        sorter: sorterNumber('costPrice'),
    },
    {
        title: t('position.columns.currency'),
        key: 'currency',
        className: 'currency-cel',
        width: 80,
        minWidth: 80,
        sorter: sorterString('currency'),
    },
    {
        title: t('position.columns.lastPrice'),
        key: 'latestPrice',
        width: 107,
        minWidth: 107,
        className: 'text-right',
        sorter: sorterNumber('latestPrice'),
    },
    {
        key: 'performance',
        title: t('position.columns.perfSI'),
        className: 'text-right',
        width: 95,
        minWidth: 95,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('performance'),
    },
    {
        title: t('position.columns.subAssetClass'),
        key: 'subAssetClass',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('subAssetClass'),
    },
    {
        title: t('position.columns.country'),
        key: 'country',
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('country'),
    },
    {
        title: t('position.columns.region'),
        key: 'region',
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('region'),
    },
    {
        title: t('position.columns.sector'),
        key: 'sector',
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('sector'),
    },
    {
        title: t('position.columns.subSector'),
        key: 'subSector',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('subSector'),
    },
    {
        title: t('position.columns.valueInSecurityCurrency'),
        className: 'text-right',
        key: 'valuationInSecurityCurrency',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterNumber('valuationInSecurityCurrency'),
    },
    {
        title: t('position.columns.FXRate'),
        key: 'fxRate',
        className: 'text-right',
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterNumber('fxRate'),
    },
    {
        title: t('position.columns.unrealizedGainLoss'),
        key: 'unrealizedGainLoss',
        className: 'text-right',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterNumber('unrealizedGainLoss'),
    },
    {
        title: t('position.columns.allocation'),
        key: 'allocation',
        className: 'text-right',
        width: 120,
        minWidth: 120,
        sorter: sorterNumber('allocation'),
    },
    {
        title: t('position.columns.value'),
        className: 'text-right',
        key: 'valuation',
        width: 150,
        minWidth: 150,
        sorter: sorterNumber('valuation'),
    },
    {
        title: '',
        className: 'table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        headerRenderer: CellWithIcon,
    },
];

export const positionsMobileColumns = (t) => [
    {
        key: 'name',
        title: t('position.columns.name'),
        sortable: true,
        render: CellSwitch,
        width: 135,
        minWidth: 135,
        sorter: sorterString('name'),
    },
    {
        title: t('position.columns.value'),
        className: 'text-right',
        key: 'valuation',
        width: 150,
        minWidth: 150,
        sorter: sorterNumber('valuation'),
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 40,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const proposalsColumns = (t) => [
    {
        key: 'Date',
        title: t('proposal.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'descend',
        width: 187,
    },
    {
        key: 'Name',
        title: t('proposal.columns.name'),
        width: 400,
        sortable: true,
        sorter: sorterString('Name'),
        render: CellSwitch,
        defaultSortOrder: 'descend',
    },
    {
        key: 'Status',
        title: t('proposal.columns.status'),
        sorter: sorterString('Status'),
    },
];

export const cashTransactionsColumns = (t, currency) => [
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.date'),
        sortable: true,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 100,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 200,
    },
    {
        key: 'BookingText',
        title: t('clientDashboard.portfolio.columns.bookingText'),
        sortable: true,
        sorter: sorterString('BookingText'),
        defaultSortOrder: 'ascend',
    },
    {
        key: 'Credit',
        title: `${t('portfolios.columns.credit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Credit'),
    },
    {
        key: 'Debit',
        title: `${t('portfolios.columns.debit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Debit'),
    },
    {
        key: 'Balance',
        title: `${t('portfolios.columns.balance')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Balance'),
    },
];

export const cashTransactionsMobileColumns = (t, currency) => [
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.date'),
        sortable: true,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 100,
        hidden: true,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 200,
    },
    {
        key: 'BookingText',
        title: t('clientDashboard.portfolio.columns.bookingText'),
        sortable: true,
        sorter: sorterString('BookingText'),
        defaultSortOrder: 'ascend',
    },
    {
        key: 'Credit',
        title: `${t('portfolios.columns.credit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Credit'),
    },
    {
        key: 'Debit',
        title: `${t('portfolios.columns.debit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Debit'),
    },
    {
        key: 'Balance',
        title: `${t('portfolios.columns.balance')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Balance'),
        hidden: true,
    },
];

export const securityTransactionsColumns = (t, currency) => [
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.date'),
        sortable: true,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 100,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 200,
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        hidden: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'SubAssetClass',
        title: t('position.columns.subAssetClass'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Country',
        title: t('position.columns.country'),
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('Country'),
    },
    {
        key: 'Region',
        title: t('position.columns.region'),
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('Region'),
    },
    {
        key: 'Sector',
        title: t('position.columns.sector'),
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('Sector'),
    },
    {
        key: 'SubSector',
        title: t('position.columns.subSector'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubSector'),
    },
    {
        key: 'Type',
        title: t('portfolios.transactionsColumns.type'),
        sorter: sorterString('Type'),
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        hidden: true,
    },
    {
        key: 'Number',
        title: t('portfolios.transactionsColumns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('portfolios.transactionsColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('portfolios.transactionsColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Value',
        title: `${t('portfolios.columns.value')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Icon',
        title: '',
        titleType: 'columns',
        width: 20,
        minWidth: 20,
        className: 'text-right',
    },
];

export const securityTransactionsMobileColumns = (t, currency) => [
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.date'),
        sortable: true,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 100,
        hidden: true,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 135,
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        hidden: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'SubAssetClass',
        title: t('position.columns.subAssetClass'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Country',
        title: t('position.columns.country'),
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('Country'),
    },
    {
        key: 'Region',
        title: t('position.columns.region'),
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('Region'),
    },
    {
        key: 'Sector',
        title: t('position.columns.sector'),
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('Sector'),
    },
    {
        key: 'SubSector',
        title: t('position.columns.subSector'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubSector'),
    },
    {
        key: 'Type',
        title: t('portfolios.transactionsColumns.type'),
        sorter: sorterString('Type'),
        hidden: true,
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        hidden: true,
    },
    {
        key: 'Number',
        title: t('portfolios.transactionsColumns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
        hidden: true,
    },
    {
        key: 'Price',
        title: t('portfolios.transactionsColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
        hidden: true,
    },
    {
        key: 'FxRate',
        title: t('portfolios.transactionsColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
        hidden: true,
    },
    {
        key: 'Value',
        title: `${t('portfolios.columns.value')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Icon',
        title: '',
        titleType: 'columns',
        width: 20,
        minWidth: 20,
        className: 'text-right',
    },
];

export const getOrdersColumns = (t) => ([
    {
        key: 'Name',
        title: t('confirmation.transactionColumns.name'),
        render: CellSwitch,
        width: 200,
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Date',
        title: t('confirmation.transactionColumns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 187,
    },
    {
        key: 'Type',
        title: t('confirmation.transactionColumns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Number',
        title: t('confirmation.transactionColumns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('confirmation.transactionColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('confirmation.transactionColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Total',
        title: t('confirmation.transactionColumns.total'),
        className: 'text-right',
        sorter: sorterNumber('Total'),
    },
]);

// TODO: unused code below, delete after approval
export const assetClassesPieChartData = [
    {
        name: 'Cash',
        value: 11340.01,
    },
    {
        name: 'Equities',
        value: 51780.01,
    },
    {
        name: 'Bonds',
        value: 36880.01,
    },
    {
        name: 'Others',
        value: 10080.01,
    },
];

export const invPrfLineChartData = [
    {
        data: [
            [1167609600000, -1],
            [1167696000000, 1],
            [1167782400000, 2.5],
            [1167868800000, 1],
            [1167955200000, 0.5],
            [1168214400000, 3],
        ],
    },
];

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = ['YTD', 'Last 12 months', '2 Years', '5 Years', 'Max'];
