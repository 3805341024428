import React from 'react';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import PropTypes from 'prop-types';
import { cashTransactionsColumns, cashTransactionsMobileColumns } from '../constants';

function TransactionsCash({
    isLoading, error, data, portfolio, onPaginationChange, page,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('portfolios.noSecurityTransactionsFound')}>
                <div className="transactions data-wrapper">
                    <Table
                        data={data}
                        columns={isMobileSize
                            ? cashTransactionsMobileColumns(t, portfolio?.currency)
                            : cashTransactionsColumns(t, portfolio?.currency)}
                        expandedColumn="Name"
                        onChange={(pagination) => {
                            if (pagination.currentPage) onPaginationChange(pagination);
                        }}
                        paging={{
                            currentPage: page,
                            pageSize: 10,
                        }}
                    />
                </div>
            </EmptyContent>
        </Preloader>
    );
}

TransactionsCash.propTypes = {
    page: PropTypes.number.isRequired,
    onPaginationChange: PropTypes.func.isRequired,
    portfolio: PropTypes.shape({
        currency: PropTypes.string,
    }),
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        Date: PropTypes.string,
        Name: PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string,
            maxLength: PropTypes.number,
        }),
        Isin: PropTypes.string,
        SubAssetClass: PropTypes.string,
        Country: PropTypes.string,
        Region: PropTypes.string,
        Sector: PropTypes.string,
        SubSector: PropTypes.string,
        Type: PropTypes.string,
        Status: PropTypes.string,
        Number: PropTypes.string,
        Price: PropTypes.string,
        FxRate: PropTypes.string,
        Value: PropTypes.string,
        Balance: PropTypes.string,
    })),
};

TransactionsCash.defaultProps = {
    error: null,
    isLoading: false,
    portfolio: undefined,
    data: [],
};

export default TransactionsCash;
