import { gql } from '@apollo/client';

export const SECURITY_ATTRIBUTES = gql`
    query CommonInstrumentData($ids: [Int!]) {
        securities(
            where: {
                id: { in: $ids }
            }
        ){
            items {
                id,
                uId
                name
                isin
                factor
                roundingLot
                tradingUnit
                tradingPrice
                isPricePercentage
                assetClass {  id uId name description isActive externalId }
                type { id name description externalId }
                sector { id uId name isActive }

                country { id uId countryCode:isoCode name phoneCode continent { id name } }
                currency { id uId name symbol }

                isInvestable
                isActive
            }
        }
    }
`;
