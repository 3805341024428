export const ADVISORY = 'advisory';
export const DISCRETIONARY = 'discretionary';
export const EXECUTION_ONLY = 'execution';
export const DEPOSITS = 'deposits';

export const ADVISORY_ID = [1, 6, 8];
export const DISCRETIONARY_ID = [3, 7, 9];
export const EXECUTION_ONLY_ID = [2, 4, 10];
export const DEPOSITS_ID = [12];


export const generateObject = (obj) => {
    const {
        [ADVISORY]: advisory,
        [DISCRETIONARY]: discretionary,
        [EXECUTION_ONLY]: executionOnly,
        [DEPOSITS]: deposits,
    } = obj;
    const avdisoryIds = ADVISORY_ID
        .reduce((acc, id) => ({ ...acc, [id]: advisory }), {});
    const discretionaryIds = DISCRETIONARY_ID
        .reduce((acc, id) => ({ ...acc, [id]: discretionary }), {});
    const executionOnlyIds = EXECUTION_ONLY_ID
        .reduce((acc, id) => ({ ...acc, [id]: executionOnly }), {});
    const depositsIds = DEPOSITS_ID
        .reduce((acc, id) => ({ ...acc, [id]: deposits }), {});
    const getByName = (name) => {
        if (!name) return null;
        if (name.toLowerCase().includes(ADVISORY)) return advisory;
        if (name.toLowerCase().includes(DISCRETIONARY)) return discretionary;
        if (name.toLowerCase().includes(EXECUTION_ONLY)) return executionOnly;
        if (name.toLowerCase().includes(DEPOSITS)) return deposits;

        return null;
    };

    return {
        ...avdisoryIds,
        ...discretionaryIds,
        ...depositsIds,
        ...executionOnlyIds,
        getByName,
    };
};
