import { checkNullData, stripHtmlTags } from 'utils';
import { getAdaptedDate } from 'utils/formatting';
import { dateTimeFormats } from 'locale';
import { getLocaleString } from 'locale/utils';

export const getNames = (data) => {
    const listOfNames = new Set();

    data.forEach((val) => listOfNames.add(val.name));

    return [...listOfNames];
};

const contractualDocumentIds = [75, 80, 81];

export const Status = {
    Pending: 'Pending',
    Signed: 'Signed',
    NA: 'N/A',
};

export const generateStatus = ({ documentMetadata, filename }) => {
    if (contractualDocumentIds.includes(documentMetadata?.id)) {
        const isSigned = filename.includes('Signed');


        return {
            text: isSigned ? Status.Signed : Status.Pending,
            color: isSigned ? 'green' : 'red',
        };
    }

    return { text: Status.NA };
};

const getIsUploadButtonVisible = (item) => generateStatus(item)?.text === Status.Pending;

const getPortfolio = ({
    contractId, portfolios, currentPage, currentTab,
}) => {
    const notAvailable = { label: 'N/A', link: '', disabled: true };

    if (!contractId || !portfolios) return notAvailable;

    const findPortfolio = portfolios.find(({ UId }) => UId === contractId);

    if (!findPortfolio) return notAvailable;

    const { Name } = findPortfolio;

    return {
        ...Name,
        state: {
            fromOrigin: 'documents',
            currentPage,
            currentTab,
        },
    };
};

export const adaptDocuments = (
    data,
    downloadDocument,
    t,
    i18n,
    getFormattedDate,
    portfolios,
    tab,
) => (data?.results || [])
    .map((item) => {
        const {
            id,
            validTill,
            createDate,
            filename,
            retrieveFileAsName,
            contractId,
            documentMetadata: {
                name,
                isActive,
                documentCategory: { name: documentTypeName },
            },
        } = item;
        const { language } = i18n;

        if (checkNullData(data)) return [];

        return {
            id,
            nameCol: {
                value: filename
                || stripHtmlTags(retrieveFileAsName || getLocaleString(name, language)),
                onDownload: downloadDocument,
            },
            valid: getFormattedDate(validTill, {
                hour: dateTimeFormats.TWO_DIGIT,
                minute: dateTimeFormats.TWO_DIGIT,
                second: dateTimeFormats.TWO_DIGIT,
            }),
            isActive: t(`advisoryDashboard.documents.${isActive ? 'yes' : 'no'}`),
            filename: filename || '',
            category: getLocaleString(name, language) || '',
            type: getLocaleString(documentTypeName) || '',
            contractId: getPortfolio({
                contractId,
                portfolios,
                currentPage: data?.page,
                currentTab: tab,
            }),
            fullDate: createDate,
            createDate: getFormattedDate(getAdaptedDate(createDate, t)),
            status: generateStatus(item ?? {}),
            metadataId: item?.documentMetadata?.id,
            actions: { showUpload: getIsUploadButtonVisible(item), item },
        };
    });
