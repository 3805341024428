import {
    useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import {
    isArray, isEmpty, isFinite, isNumber, isObject, isString,
} from 'lodash';

export const useSchema = (options = {}) => {
    const { loadInitially = true } = options;
    const [schemasList, setSchemasList] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const { i18n: { language } } = useTranslation();

    const flatArrayData = (array, resultingArray) => {
        if (array == null || !isArray(array) || isEmpty(array)) {
            return [];
        }

        Object.keys(array).forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(array, element)) {
                if (isObject(array[element])
                    && !isEmpty(array[element])
                    && array[element].listItems != null
                    && isArray(array[element].listItems)) {
                    flatArrayData(array[element].listItems, resultingArray);
                } else if (array[element].id != null
                    && isNumber(array[element].id)
                    && isFinite(array[element].id)
                    && array[element].label != null
                    && isString(array[element].label)) {
                    if (!resultingArray.some((x) => x.id === array[element].id)) {
                        resultingArray.push({
                            id: array[element].id,
                            label: array[element].label,
                        });
                    }
                }
            }
        });

        return resultingArray;
    };

    const getSchemasList = useCallback(async () => {
        setLoading(true);
        try {
            const { data: { items } } = await SM.clientService('getSchemasLists', []);
            const flatItems = flatArrayData(items, []);

            setSchemasList(flatItems);
            setLoading(false);

            return items;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    const getSchema = useCallback(async ({ key }) => {
        setLoading(true);
        try {
            const { data: { items } } = await SM.clientService('getSchema', [key]);

            setLoading(false);

            return items;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    const getSchemaListByKey = useCallback(async ({ key }) => {
        setLoading(true);
        try {
            const { data: { items } } = await SM.clientService('getSchemaListByKey', [key]);

            setLoading(false);

            return items;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    const getSchemaLabelById = useCallback((itemId) => {
        const itemFound = schemasList?.find((item) => item?.id === itemId);

        return itemFound ? itemFound?.label : null;
    }, [schemasList]);


    useEffect(() => {
        if (loadInitially) getSchemasList();
    }, [loadInitially]);

    return {
        schemasList,
        error,
        isLoading,
        getSchema,
        getSchemasList,
        getSchemaListByKey,
        getSchemaLabelById,
    };
};
